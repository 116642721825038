import * as  React from "react";
import { Button, Flex, Space, Divider, Col, Row, Card, Pagination, theme, Image, Dropdown } from "antd";
const { useToken } = theme;

const Title = React.memo(({ children }) => {
    const { token } = useToken();
    return (
     
    <div style={{ fontSize:16, fontWeight:700, color: token.colorTextLabel, textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap"}}>
        {children}
    </div>
    
)
})


export { Title }