import BaseService from "services/base";
import _ from "lodash";

class TagService extends BaseService {
  list = async ({info}={}) => {
    var data = await this._api.post("tag/list", {info});
    return data;
  };

  add = async ({ title, color }) => {
    var data = await this._api.post("tag/save", { title, color });
    return data;
  };

  remove = async (id) => {
    var data = await this._api.post("tag/delete", { id });
    return data;
  };


  edit = async ({ id, title, color }) => {
    var data = await this._api.post("tag/update", { id, title, color });
    return true;
  };

  getByID = async (id) => {
    var data = await this._api.get("tag/get", { params: { id } });
    return data;
}
}

export default TagService;
