import React, { useState, createContext, useContext } from 'react';
import * as Icon from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import PageRoutes from "./PageRoutes"
import LeftMenu from "./LeftMenu"
import { BrowserRouter } from "react-router-dom";
import AppProvider from "context/app"
const { Header, Content, Footer, Sider } = Layout;

const AppLayoutContext = createContext();


const MainLayout = () => {
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const {
    token
  } = theme.useToken();
  return (
    <AppLayoutContext.Provider value={{ menuCollapsed, setMenuCollapsed }}>
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <LeftMenu />

        <Layout>
          {/* <Header
            style={{
              padding: 0,
              background: colorBgContainer,
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}
          /> */}
          <Content
            style={{
              margin: '0 16px',

            }}
          >

            <div
              style={{

                minHeight: "100vh",
                background: token.colorBgLayout,
 
              }}
            >
              <PageRoutes></PageRoutes>
            </div>
          </Content>

        </Layout>
      </Layout>
    </AppLayoutContext.Provider>
  );
};

const useAppLayoutContext = () => {
  return useContext(AppLayoutContext);
}


const App = () => {
  return (
    <BrowserRouter basename="/">
      <AppProvider>
        <MainLayout></MainLayout>
      </AppProvider>
    </BrowserRouter>
  )
}
export { useAppLayoutContext }
export default App;