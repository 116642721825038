import { Modal, Divider, Form, Flex, Select, Image, InputNumber, Row, Col, Button } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

function InputPlusMinusNumber({ value, onChange }) {
  const increment = () => {
    if (value === undefined) {
      value = 0;
    }
    onChange(value + 1);
  };

  const decrement = () => {
    if (value === undefined) {
      value = 0;
    }
    onChange(value - 1);
  };

  const formatValue = (value) => {
    if (value === undefined || value === null) return "";
    if (value > 0) {
      return `+${value}`;
    }
    return `${value}`;
  };

  return (
    <Flex gap={10}>
      <Button onClick={decrement}>
        <MinusOutlined />
      </Button>
      <InputNumber style={{ width: "100%" }} value={formatValue(value)} onChange={(val) => onChange(val)} controls={false} />
      <Button onClick={increment}>
        <PlusOutlined />
      </Button>
    </Flex>
  );
}

export default InputPlusMinusNumber;
