import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider, Input, Switch, theme, Card, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useItemsScreenContext } from "pages/items";
import { useAppContext } from "context/app";
const { useToken } = theme;

const ItemSelectedMenuBar = () => {
  const { token } = useToken();
  const { selectedItems, setSelectedItems, currentFolder } = useItemsScreenContext();
  const { showExportModal, showCreateLabelModal, showUpdateMultiQuantityModal, showMoveMultiQuantityModal, showMoveFolderModal, showSetTagModal, showAlertModal } = useAppContext();
  const [disableUpdateQuantity, setDisableUpdateQuantity] = useState();

  useEffect(() => {
    if (selectedItems?.length > 0) {
      const hasFolder = selectedItems.some((item) => item.entity_type === "folder");
      setDisableUpdateQuantity(hasFolder);
    }
  }, [selectedItems]);

  if (selectedItems.length <= 0) return null;

  const options = [
    {
      key: "add_tag",
      label: <div>Add tags</div>,
    },
    {
      key: "remove_tag",
      label: <div>Remove tags</div>,
    },
  ];

  const menuClick = ({ item, key, keyPath, domEvent }) => {
    console.log({ item, key, keyPath });

    switch (key) {
      case "add_tag":
        showSetTagModal(selectedItems, currentFolder, "Add");
        break;
      case "remove_tag":
        showSetTagModal(selectedItems, currentFolder, "Remove");
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ padding: token.padding, backgroundColor: token.colorBgContainer, marginBottom: token.marginLG, borderRadius: token.borderRadius, display: "flex", }}>
      <div style={{ flexGrow: 1 }}>
        {selectedItems.length} Items selected
        <Button type="link" onClick={(e) => setSelectedItems([])}>
          Clear
        </Button>
      </div>
      <div>
        <Space>
          <Button type="link">Edit</Button>
          <Button
            type="link"
            onClick={() => {
              showUpdateMultiQuantityModal(selectedItems, currentFolder);
            }}
            disabled={disableUpdateQuantity}
          >
            Update Quantity
          </Button>
          <Button
            type="link"
            onClick={() => {
              const checkHasFolder = selectedItems.some((item) => item.entity_type === "folder");
              if (checkHasFolder) {
                showMoveFolderModal(selectedItems, currentFolder);
              } else {
                showMoveMultiQuantityModal(selectedItems, currentFolder);
              }
            }}
          >
            Move
          </Button>
          <Button
            type="link"
            onClick={() => {
              showExportModal(selectedItems, currentFolder);
            }}
          >
            Export
          </Button>
          <Button
            type="link"
            onClick={() => {
              showCreateLabelModal(selectedItems, currentFolder);
            }}
          >
            Create Label
          </Button>
          <Dropdown
            menu={{
              items: options,
              onClick: menuClick,
            }}
          >
            <Button type="link">
              <span>Tags</span>
              <DownOutlined style={{ marginTop: "4px" }} />
            </Button>
          </Dropdown>
          <Button
            type="link"
            onClick={() => {
              showAlertModal(selectedItems, currentFolder);
            }}
            disabled={disableUpdateQuantity}
          >
            Alert
          </Button>
          <Button type="link">Clone</Button>
          <Button type="link">Delete</Button>
        </Space>
      </div>
    </div>
  );
};

export default memo(ItemSelectedMenuBar);
