function CompactSVG() {
  return (
    <svg width="45" height="58" viewBox="0 0 45 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="43" height="56" rx="2" stroke="currentColor" stroke-width="2"></rect>
      <rect x="6" y="6.71875" width="6.74782" height="6.74782" rx="0.4" fill="currentColor"></rect>
      <rect x="14.0156" y="6.71875" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="14.0156" y="9.46094" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="14.0156" y="12.2031" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="6" y="16.1719" width="6.74782" height="6.74782" rx="0.4" fill="currentColor"></rect>
      <rect x="14.0156" y="16.1719" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="14.0156" y="18.9141" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="14.0156" y="21.6562" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="6" y="25.625" width="6.74782" height="6.74782" rx="0.4" fill="currentColor"></rect>
      <rect x="14.0156" y="25.625" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="14.0156" y="28.3672" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="14.0156" y="31.1094" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="6" y="35.0781" width="6.74782" height="6.74782" rx="0.4" fill="currentColor"></rect>
      <rect x="14.0156" y="35.0781" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="14.0156" y="37.8203" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="14.0156" y="40.5625" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="6" y="44.5312" width="6.74782" height="6.74782" rx="0.4" fill="currentColor"></rect>
      <rect x="14.0156" y="44.5312" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="14.0156" y="47.2734" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="14.0156" y="50.0156" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="23.9258" y="6.71875" width="6.74782" height="6.74782" rx="0.4" fill="currentColor"></rect>
      <rect x="31.9424" y="6.71875" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="31.9424" y="9.46094" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="31.9424" y="12.2031" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="23.9258" y="16.1719" width="6.74782" height="6.74782" rx="0.4" fill="currentColor"></rect>
      <rect x="31.9424" y="16.1719" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="31.9424" y="18.9141" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="31.9424" y="21.6562" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="23.9258" y="25.625" width="6.74782" height="6.74782" rx="0.4" fill="currentColor"></rect>
      <rect x="31.9424" y="25.625" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="31.9424" y="28.3672" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="31.9424" y="31.1094" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="23.9258" y="35.0781" width="6.74782" height="6.74782" rx="0.4" fill="currentColor"></rect>
      <rect x="31.9424" y="35.0781" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="31.9424" y="37.8203" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="31.9424" y="40.5625" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="23.9258" y="44.5312" width="6.74782" height="6.74782" rx="0.4" fill="currentColor"></rect>
      <rect x="31.9424" y="44.5312" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="31.9424" y="47.2734" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
      <rect x="31.9424" y="50.0156" width="7.05604" height="1.26522" rx="0.3" fill="currentColor"></rect>
    </svg>
  );
}

export default CompactSVG;
