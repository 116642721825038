import BaseService from "services/base";
import _ from "lodash";

class ImageService extends BaseService {
  /**
   * hàm upload, 
   * @param {FormData} formdata 
   * @returns {id, url}
   */
  upload = async (formdata) => {
    var image = this._api.post("image/upload", formdata)
    return image;
  };



}

export default ImageService;
