import { Modal, Divider, Form, Flex, Select, Image, InputNumber, Input, Button, Switch } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { SaveButton } from "components/Buttons";
import ItemService from "services/item";
import { useLoading } from "context/loading";
import { useAppContext } from "context/app";
import { getQuantityItem } from "common/get_quantity_item";
import { useNotification } from "context/notification";
import * as Picker from "components/pickers";
import SelectMoveReason from "./SelectMoveReason";
import InputQuantityMove from "./InputQuantityMove";

const itemService = new ItemService();

function MoveSingleQuantityItemModal({ item, folderId, visible, onClose }) {
  const { showLoading, dismissLoading } = useLoading();
  const { success, error } = useNotification();
  const [disableSave, setDisableSave] = useState(true);
  const [hasMoveAll, setHasMoveAll] = useState(true);
  const [form] = Form.useForm();
  const { eventBus } = useAppContext();
  const quantityOfItem = item && getQuantityItem(item, folderId);

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        quantity_to_move: 1,
        to_folder_id: folderId,
      });

      checkErrors(1);
    }
  }, [item]);

  const handleChangeQuantityMove = (value) => {
    checkErrors(value);
  };

  const checkErrors = (value) => {
    if (value !== quantityOfItem) {
      setHasMoveAll(true);
    } else {
      setHasMoveAll(false);
    }

    // Kiểm tra lỗi
    if (value === undefined || value === null) {
      form.setFields([
        {
          name: "quantity_to_move",
          errors: ["Quantity can't be blank"],
        },
      ]);
      setDisableSave(true);
    } else if (value <= 0) {
      form.setFields([
        {
          name: "quantity_to_move",
          errors: ["Quantity To Move can't be below 1"],
        },
      ]);
      setDisableSave(true);
    } else if (value > quantityOfItem) {
      form.setFields([
        {
          name: "quantity_to_move",
          errors: [`Quantity To Move must be less than or equal to ${quantityOfItem}`],
        },
      ]);
      setDisableSave(true);
    } else {
      form.setFields([
        {
          name: "quantity_to_move",
          errors: null,
        },
      ]);
      setDisableSave(false);
    }
  };

  const handleBtnMoveAll = () => {
    form.setFieldsValue({
      quantity_to_move: quantityOfItem,
    });
    checkErrors(quantityOfItem);
    setHasMoveAll(false);
  };

  const handleSave = async () => {
    showLoading();

    try {
      const data = {
        item_id: item.id,
        folder_id: folderId,
        to_folder_id: form.getFieldValue("to_folder_id"),
        quantity: form.getFieldValue("quantity_to_move"),
        notes: form.getFieldValue("notes"),
        reason_id: form.getFieldValue("reason"),
      };

      console.log(data);

      const result = await itemService.moveQuantity(data);
      eventBus.emit("item_changed", { folder_id: folderId });
      success(`Move quantity has been successfully updated`);
    } catch (err) {
      error("Failed to move quantity. Please try again later.");
      console.log(err);
    } finally {
      resetValue();
      dismissLoading();
    }
  };

  const resetValue = () => {
    form.resetFields();
    setDisableSave(true);
    setHasMoveAll(true);
    onClose();
  };

  return (
    <Modal
      width={500}
      title={<p style={{ fontSize: "18px", textAlign: "center" }}>Move Item</p>}
      open={visible}
      onCancel={resetValue}
      maskClosable={false}
      footer={<SaveButton disabled={disableSave} onClick={handleSave} />}
    >
      <Divider style={{ margin: "10px 0" }} />

      <span style={{ display: "block", fontSize: "20px", fontWeight: 500 }}>{item && item.name}</span>
      <span style={{ display: "block", marginBottom: "10px" }}>{item && item.variant_title}</span>

      <Form form={form} layout="vertical">
        <Flex align="center" justify="space-between">
          <Form.Item name="quantity_to_move" label="Quantity to move" style={{ width: "80%" }}>
            <InputQuantityMove value={form.getFieldValue("quantity_to_move")} quantityOfItem={quantityOfItem} onChange={handleChangeQuantityMove} />
          </Form.Item>
          {hasMoveAll && (
            <Button type="link" onClick={handleBtnMoveAll} style={{ paddingTop: "10px" }}>
              Move all
            </Button>
          )}
        </Flex>

        <Form.Item name="reason" label="Move reason">
          <SelectMoveReason />
        </Form.Item>

        <Form.Item name="notes" label="Move notes">
          <Input.TextArea />
        </Form.Item>

        <Divider style={{ margin: "10px 0" }} />

        <Form.Item name="to_folder_id" label="Choose destination folder:">
          <Picker.Folder style={{ width: "100%" }} placeholder=""></Picker.Folder>
        </Form.Item>
        <Divider />
      </Form>
    </Modal>
  );
}

export default MoveSingleQuantityItemModal;
