import { Button, Col, Input, Modal, Row, Form, Switch, Checkbox, InputNumber, Select, DatePicker } from "antd";
import { FontSizeOutlined, CheckSquareOutlined, FieldBinaryOutlined, DownSquareOutlined, PhoneOutlined, MailOutlined, LinkOutlined, PlusOutlined, QrcodeOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useRef, useState } from "react";
import * as field_type_const from "components/CustomField/constants";
import dayjs from "dayjs";

function FieldOptionsCustomField({ form, selectedField, value = null, setDisableSave, listOption, setListOption, typeField }) {
  const [name, setName] = useState("Field Name");
  const placeholder = Form.useWatch("placeholder", { form });
  const default_value = Form.useWatch("default_value", { form });
  const item = Form.useWatch("item", { form });
  const folder = Form.useWatch("folder", { form });
  const round_number_unit = Form.useWatch("round_number_unit", { form });

  const inputNameRef = useRef(null);
  const today = dayjs();

  useEffect(() => {
    if (typeField === "SUGGESTED" && selectedField) {
      form.setFieldsValue({ name: value?.name || selectedField.name });
      setName(value?.name || selectedField.name);
    } else {
      form.setFieldsValue({ name: value?.name || "" });
    }

    inputNameRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (!default_value) {
      form.setFieldsValue({ apply_all_existing_items: false });
    }
  }, [default_value]);

  useEffect(() => {
    if (default_value && listOption?.length > 0 && !listOption?.includes(default_value)) {
      form.setFieldsValue({ default_value: undefined });
    }
  }, [listOption]);

  useEffect(() => {
    // kiểm tra các rule có đang error
    const checkErrors = async () => {
      try {
        await form.validateFields();
        setDisableSave(false);
      } catch (error) {
        setDisableSave(true);
      }
    };

    const disable = name === "Field Name" || (!value && !item && !folder) || (selectedField?.data_type === field_type_const.DROPDOWN && listOption.length === 0);

    if (!disable) {
      checkErrors();
    } else {
      setDisableSave(true);
    }
  }, [name, item, folder, default_value, selectedField, listOption]);

  const showInputsDetail = (selectedField) => {
    switch (selectedField.data_type) {
      case field_type_const.SHORT_TEXT:
        return (
          <>
            <Form.Item
              label="Field Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
              initialValue={name}
            >
              <Input
                ref={inputNameRef}
                placeholder="Field Name"
                onChange={(e) => {
                  setName(e.target.value || "Field Name");
                }}
                maxLength={190}
              />
            </Form.Item>

            <Form.Item name="default_value" label="Default Text">
              <Input placeholder="Enter Default Text" maxLength={190} />
            </Form.Item>

            <Form.Item label="Apply default value to all existing items" name="apply_all_existing_items" layout="horizontal" valuePropName="checked">
              <Checkbox disabled={value ? true : !default_value} />
            </Form.Item>

            <Form.Item name="placeholder" label="Placeholder Text">
              <Input placeholder="Enter Placeholder Text" maxLength={190} />
            </Form.Item>
          </>
        );
      case field_type_const.LONG_TEXT:
        return (
          <>
            <Form.Item
              label="Field Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                ref={inputNameRef}
                placeholder="Field Name"
                onChange={(e) => {
                  setName(e.target.value || "Field Name");
                }}
                maxLength={4000}
              />
            </Form.Item>

            <Form.Item name="default_value" label="Default Text">
              <Input placeholder="Enter Default Text" maxLength={4000} />
            </Form.Item>

            <Form.Item label="Apply default value to all existing items" name="apply_all_existing_items" layout="horizontal" valuePropName="checked">
              <Checkbox disabled={value ? true : !default_value} />
            </Form.Item>

            <Form.Item name="placeholder" label="Placeholder Text">
              <Input placeholder="Enter Placeholder Text" maxLength={4000} />
            </Form.Item>
          </>
        );
      case field_type_const.CHECK_BOX:
        return (
          <>
            <Form.Item
              label="Field Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                ref={inputNameRef}
                placeholder="Field Name"
                onChange={(e) => {
                  setName(e.target.value || "Field Name");
                }}
              />
            </Form.Item>

            <Form.Item label="Selected by default" name="default_value" layout="horizontal" valuePropName="checked" initialValue={value?.default_value || false}>
              <Checkbox />
            </Form.Item>

            <Form.Item label="Apply default value to all existing items" name="apply_all_existing_items" layout="horizontal" valuePropName="checked">
              <Checkbox disabled={value ? true : !default_value} />
            </Form.Item>
          </>
        );
      case field_type_const.ROUND_NUMBER:
        return (
          <>
            <Form.Item
              label="Field Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                ref={inputNameRef}
                placeholder="Field Name"
                onChange={(e) => {
                  setName(e.target.value || "Field Name");
                }}
              />
            </Form.Item>

            <div style={{ display: "flex", gap: "12px" }}>
              <Form.Item name="default_value" label="Default Value">
                <InputNumber
                  placeholder="Enter Default Value"
                  min={0}
                  style={{ width: "100%" }}
                  onKeyDown={(e) => {
                    // Chặn nhập các ký tự không phải số
                    if (!/^[0-9]$/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>

              <Form.Item name="round_number_unit" label="Units">
                <Input placeholder="Enter Units" />
              </Form.Item>
            </div>

            <Form.Item label="Apply default value to all existing items" name="apply_all_existing_items" layout="horizontal" valuePropName="checked">
              <Checkbox disabled={value ? true : !default_value} />
            </Form.Item>

            <Form.Item name="placeholder" label="Placeholder Text">
              <Input placeholder="Enter Placeholder Text" />
            </Form.Item>
          </>
        );
      case field_type_const.DECIMAL_NUMBER:
        return (
          <>
            <Form.Item
              label="Field Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                ref={inputNameRef}
                placeholder="Field Name"
                onChange={(e) => {
                  setName(e.target.value || "Field Name");
                }}
              />
            </Form.Item>

            <div style={{ display: "flex", gap: "12px" }}>
              <Form.Item name="default_value" label="Default Value">
                <InputNumber
                  placeholder="Enter Default Value"
                  min={0}
                  step={0.01}
                  style={{ width: "100%" }}
                  onKeyDown={(e) => {
                    // Chặn nhập các ký tự không phải số và dấu chấm thập phân
                    if (!/^[0-9.]$/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>

              <Form.Item name="round_number_unit" label="Units">
                <Input placeholder="Enter Units" />
              </Form.Item>
            </div>

            <Form.Item label="Apply default value to all existing items" name="apply_all_existing_items" layout="horizontal" valuePropName="checked">
              <Checkbox disabled={value ? true : !default_value} />
            </Form.Item>

            <Form.Item name="placeholder" label="Placeholder Text">
              <Input placeholder="Enter Placeholder Text" />
            </Form.Item>
          </>
        );
      case field_type_const.DROPDOWN:
        return (
          <>
            <Form.Item
              label="Field Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                ref={inputNameRef}
                placeholder="Field Name"
                onChange={(e) => {
                  setName(e.target.value || "Field Name");
                }}
              />
            </Form.Item>

            <Form.Item
              label="Options"
              name="options"
              rules={[
                {
                  validator: validateOptions,
                },
              ]}
            >
              <Input.TextArea
                placeholder="Type at least 1 option. Separate options by line break"
                style={{ height: "70px" }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setListOption([]);
                    return;
                  }
                  const optionsArray = e.target.value?.trim().split("\n");
                  setListOption(optionsArray);
                }}
              />
            </Form.Item>

            <Form.Item name="default_value" label="Default Option">
              <Select placeholder="Enter Default Option">
                {listOption?.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Apply default value to all existing items" name="apply_all_existing_items" layout="horizontal" valuePropName="checked">
              <Checkbox disabled={value ? true : !default_value} />
            </Form.Item>

            <Form.Item name="placeholder" label="Placeholder Text">
              <Input placeholder="Enter Placeholder Text" maxLength={190} />
            </Form.Item>
          </>
        );
      case field_type_const.PHONE_NUMBER:
        return (
          <>
            <Form.Item
              label="Field Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                ref={inputNameRef}
                placeholder="Field Name"
                onChange={(e) => {
                  setName(e.target.value || "Field Name");
                }}
              />
            </Form.Item>

            <Form.Item name="default_value" label="Default Text" rules={[{ validator: validatePhoneNumber }]}>
              <Input placeholder="Enter Default Text" />
            </Form.Item>

            <Form.Item label="Apply default value to all existing items" name="apply_all_existing_items" layout="horizontal" valuePropName="checked">
              <Checkbox disabled={value ? true : !default_value} />
            </Form.Item>

            <Form.Item name="placeholder" label="Placeholder Text">
              <Input placeholder="Enter Placeholder Text" />
            </Form.Item>
          </>
        );
      case field_type_const.EMAIL:
        return (
          <>
            <Form.Item
              label="Field Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                ref={inputNameRef}
                placeholder="Field Name"
                onChange={(e) => {
                  setName(e.target.value || "Field Name");
                }}
              />
            </Form.Item>

            <Form.Item name="default_value" label="Default Text" rules={[{ type: "email", message: "Default value must be valid email" }]}>
              <Input placeholder="Enter Default Text" />
            </Form.Item>

            <Form.Item label="Apply default value to all existing items" name="apply_all_existing_items" layout="horizontal" valuePropName="checked">
              <Checkbox disabled={value ? true : !default_value} />
            </Form.Item>

            <Form.Item name="placeholder" label="Placeholder Text">
              <Input placeholder="Enter Placeholder Text" />
            </Form.Item>
          </>
        );
      case field_type_const.LINK:
        return (
          <>
            <Form.Item
              label="Field Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                ref={inputNameRef}
                placeholder="Field Name"
                onChange={(e) => {
                  setName(e.target.value || "Field Name");
                }}
              />
            </Form.Item>

            <Form.Item name="default_value" label="Default Text" rules={[{ validator: validateUrl }]}>
              <Input placeholder="Enter Default Text" />
            </Form.Item>

            <Form.Item label="Apply default value to all existing items" name="apply_all_existing_items" layout="horizontal" valuePropName="checked">
              <Checkbox disabled={value ? true : !default_value} />
            </Form.Item>

            <Form.Item name="placeholder" label="Placeholder Text">
              <Input placeholder="Enter Placeholder Text" />
            </Form.Item>
          </>
        );
      case field_type_const.DATE:
        return (
          <>
            <Form.Item
              label="Field Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                ref={inputNameRef}
                placeholder="Field Name"
                onChange={(e) => {
                  setName(e.target.value || "Field Name");
                }}
              />
            </Form.Item>

            <Form.Item name="default_value" label="Default Value">
              <DatePicker format="DD/MM/YYYY" placeholder="Enter Default Value" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item label="Apply default value to all existing items" name="apply_all_existing_items" layout="horizontal" valuePropName="checked">
              <Checkbox disabled={value ? true : !default_value} />
            </Form.Item>

            <Form.Item name="placeholder" label="Placeholder Text">
              <Input placeholder="Enter Placeholder Text" />
            </Form.Item>
          </>
        );
      case field_type_const.FILE:
        return (
          <>
            <Form.Item
              label="Field Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
              initialValue={name}
            >
              <Input
                ref={inputNameRef}
                placeholder="Field Name"
                onChange={(e) => {
                  setName(e.target.value || "Field Name");
                }}
                maxLength={190}
              />
            </Form.Item>
          </>
        );
      case field_type_const.SCANNER:
        return (
          <>
            <Form.Item
              label="Field Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
              initialValue={name}
            >
              <Input
                ref={inputNameRef}
                placeholder="Field Name"
                onChange={(e) => {
                  setName(e.target.value || "Field Name");
                }}
                maxLength={190}
              />
            </Form.Item>
          </>
        );
      default:
        break;
    }
  };

  const showReview = (selectedField) => {
    switch (selectedField.data_type) {
      case field_type_const.SHORT_TEXT:
        return (
          <>
            <Form.Item label={name}>
              <Input value="Sample Text" readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>

            <Form.Item label={name}>
              <Input value={default_value} readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>

            <Form.Item label={name}>
              <Input placeholder={placeholder} readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>
          </>
        );
      case field_type_const.LONG_TEXT:
        return (
          <>
            <Form.Item label={name}>
              <Input value="Sample Text" readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>

            <Form.Item label={name}>
              <Input value={default_value} readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>

            <Form.Item label={name}>
              <Input placeholder={placeholder} readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>
          </>
        );
      case field_type_const.CHECK_BOX:
        return (
          <>
            <label>Sample Value:</label>
            <Form.Item label={name} name="" layout="horizontal">
              <Checkbox checked={true} style={{ pointerEvents: "none" }} />
            </Form.Item>

            <label>Field with Default Value:</label>
            <Form.Item label={name} name="" layout="horizontal">
              <Checkbox checked={default_value || false} style={{ pointerEvents: "none" }} />
            </Form.Item>
          </>
        );
      case field_type_const.ROUND_NUMBER:
        return (
          <>
            <Form.Item label={round_number_unit ? `${name}, ${round_number_unit}` : `${name}`}>
              <Input value="Sample Text" readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>

            <Form.Item label={round_number_unit ? `${name}, ${round_number_unit}` : `${name}`}>
              <Input value={default_value} readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>

            <Form.Item label={round_number_unit ? `${name}, ${round_number_unit}` : `${name}`}>
              <Input placeholder={placeholder} readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>
          </>
        );
      case field_type_const.DECIMAL_NUMBER:
        return (
          <>
            <Form.Item label={round_number_unit ? `${name}, ${round_number_unit}` : `${name}`}>
              <Input value="Sample Text" readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>

            <Form.Item label={round_number_unit ? `${name}, ${round_number_unit}` : `${name}`}>
              <Input value={default_value} readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>

            <Form.Item label={round_number_unit ? `${name}, ${round_number_unit}` : `${name}`}>
              <Input placeholder={placeholder} readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>
          </>
        );
      case field_type_const.DROPDOWN:
        return (
          <>
            <Form.Item label={name}>
              <Select value="Sample Options" readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>

            <Form.Item label={name}>
              <Select value={default_value} readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>

            <Form.Item label={name}>
              <Select placeholder={placeholder} readOnly style={{ pointerEvents: "none" }} />
            </Form.Item>
          </>
        );
      case field_type_const.PHONE_NUMBER:
        return (
          <>
            <Form.Item label={name}>
              <Input value="(123) 350 - 2345" readOnly style={{ pointerEvents: "none" }} addonAfter={<PhoneOutlined />} />
            </Form.Item>

            <Form.Item label={name}>
              <Input value={default_value} readOnly style={{ pointerEvents: "none" }} addonAfter={<PhoneOutlined />} />
            </Form.Item>

            <Form.Item label={name}>
              <Input placeholder={placeholder} readOnly style={{ pointerEvents: "none" }} addonAfter={<PhoneOutlined />} />
            </Form.Item>
          </>
        );
      case field_type_const.EMAIL:
        return (
          <>
            <Form.Item label={name}>
              <Input value="mail@example.com" readOnly style={{ pointerEvents: "none" }} addonAfter={<MailOutlined />} />
            </Form.Item>

            <Form.Item label={name}>
              <Input value={default_value} readOnly style={{ pointerEvents: "none" }} addonAfter={<MailOutlined />} />
            </Form.Item>

            <Form.Item label={name}>
              <Input placeholder={placeholder} readOnly style={{ pointerEvents: "none" }} addonAfter={<MailOutlined />} />
            </Form.Item>
          </>
        );
      case field_type_const.LINK:
        return (
          <>
            <Form.Item label={name}>
              <Input value="https://www.google.com" readOnly style={{ pointerEvents: "none" }} addonAfter={<LinkOutlined />} />
            </Form.Item>

            <Form.Item label={name}>
              <Input value={default_value} readOnly style={{ pointerEvents: "none" }} addonAfter={<LinkOutlined />} />
            </Form.Item>

            <Form.Item label={name}>
              <Input placeholder={placeholder} readOnly style={{ pointerEvents: "none" }} addonAfter={<LinkOutlined />} />
            </Form.Item>
          </>
        );
      case field_type_const.DATE:
        return (
          <>
            <Form.Item label={name}>
              <DatePicker format={"DD/MM/YYYY"} value={today} readOnly style={{ pointerEvents: "none", width: "100%" }} />
            </Form.Item>

            <Form.Item label={name}>
              <DatePicker format={"DD/MM/YYYY"} value={default_value} readOnly style={{ pointerEvents: "none", width: "100%" }} />
            </Form.Item>

            <Form.Item label={name}>
              <DatePicker placeholder={placeholder} readOnly style={{ pointerEvents: "none", width: "100%" }} />
            </Form.Item>
          </>
        );
      case field_type_const.FILE:
        return (
          <>
            <Form.Item label={name}>
              <Button readOnly style={{ pointerEvents: "none" }} icon={<PlusOutlined />}>
                Click to Upload
              </Button>
            </Form.Item>
          </>
        );
      case field_type_const.SCANNER:
        return (
          <>
            <Form.Item label={name}>
              <Button readOnly style={{ pointerEvents: "none" }} icon={<QrcodeOutlined />}>
                Qr/Bar code
              </Button>
            </Form.Item>
          </>
        );
      default:
        break;
    }
  };

  const validateOptions = (_, value) => {
    if (!value) {
      return Promise.reject("Add at least 1 option");
    }

    const uniqueOptions = new Set(listOption);
    if (uniqueOptions.size !== listOption.length) {
      return Promise.reject(new Error("Option names should be unique"));
    }

    return Promise.resolve();
  };

  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (value && !phoneRegex.test(value)) {
      return Promise.reject(new Error("Default value - incorrect, enter valid phone number"));
    }

    return Promise.resolve();
  };

  const validateUrl = (_, value) => {
    if (value && !/^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
      return Promise.reject(new Error("Default value must be a valid URL"));
    }
    return Promise.resolve();
  };

  return (
    <div style={{ display: "flex", marginBottom: "30px" }}>
      <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 25, marginRight: "20px" }}>
        <Form layout="vertical" form={form} initialValues={{ item: true, folder: false }} onFinish={() => {}}>
          <p>FIELD OPTIONS</p>

          {selectedField && showInputsDetail(selectedField)}

          {!value && (
            <>
              <p>APPLICABLE TO:</p>
              <p>Choose if the field should be applied to items, folders or both. This cannot be changed after the field has been created.</p>

              <Form.Item label="Items" name="item" valuePropName="checked" layout="horizontal">
                <Switch />
              </Form.Item>

              <Form.Item label="Folders" name="folder" valuePropName="checked" layout="horizontal">
                <Switch />
              </Form.Item>
            </>
          )}
        </Form>
      </div>

      <div style={{ flex: 1, backgroundColor: "rgb(246, 248, 249)", borderRadius: "20px", display: "flex", flexDirection: "column", padding: "20px 10px" }}>
        <div style={{ backgroundColor: "#fff", padding: "0 20px", borderRadius: "8px", paddingTop: "30px" }}>{selectedField && <Form layout="vertical">{showReview(selectedField)}</Form>}</div>
        <div dangerouslySetInnerHTML={{ __html: selectedField?.description }} style={{ padding: "16px" }} />
      </div>
    </div>
  );
}

export default FieldOptionsCustomField;
