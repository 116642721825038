import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider } from "antd";

import { BrowserRouter, useNavigate, useParams } from "react-router-dom";
import FolderService from "services/folder";

import ItemEditor from "components/folder/Editor"
import { useLoading } from "context/loading";
const folderService = new FolderService();




const FolderEditPage = () => {
    const navigate = useNavigate();
    const [item, setItem] = useState({});
   
    let { id } = useParams();

    const loading = useLoading();

    useEffect(() => {
        loadItem();
    }, [id]);


    const loadItem = async () => {

        loading.showLoading();
        var data = await folderService.getByID(id);
        setItem(data);
        loading.dismissLoading();
    
    }





    return (
        <div>

            <ItemEditor data={item}></ItemEditor>
        </div>
    )
}










export default memo(FolderEditPage);