import BaseService from "services/base";
import _ from "lodash";

class ItemAlertService extends BaseService {

    setLowStockAlert = async ({ item_id, folder_id, quantity, reason_id }) => {
        var data = await this._api.post("item/alert/setlowstock", { item_id, alert: { folder_id, quantity } });
        return data;
    };


    removeLowStockAlert = async ({ item_id, folder_id }) => {
        var data = await this._api.post("item/alert/removelowstock", { item_id, folder_id });
        return data;
    };


    /**
     * 
     * @param {Array({ item_id, alert: {folder_id, quantity}, reason_id })} list 
     * @returns 
     */
    bulkSetLowStockAlert = async (list) => {
        var data = await this._api.post("item/alert/bulksetlowstock", list);
        return data;
    };


    /**
     * 
     * @param {Array({ item_id, folder_id })} list 
     * @returns 
     */
    bulkRemoveLowStockAlert = async (list) => {
        var data = await this._api.post("item/alert/bulkremovelowstock", list);
        return data;
    };


}

export default ItemAlertService;
