import { useEffect, memo } from "react";
import { Button, Flex, Space, Divider, Col, Row, Card, Pagination } from "antd";
import Item from "components/item";
import _ from "lodash"
import * as ItemList from "components/item-list"
const List = ({ items = [] , loading}) => {

    return (
        <div>
            <ItemList.Gird items={items} loading={loading}></ItemList.Gird>

        </div>
    )
}




export default memo(List);