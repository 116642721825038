import React, { useState, memo } from 'react';
import { Button, Form, Input, Radio, Row, Col, Checkbox, Space, Divider, Layout, theme } from 'antd';

const { Header, Content, Footer } = Layout;
const { useToken } = theme;

const StickyBar = ({ children }) => {
    const [form] = Form.useForm();
    const { token } = useToken();

    return (

        <div style={{
            flexDirection: "row", display: "flex", position: "sticky",
            top: 0,
            zIndex: 3,
            backgroundColor: token.colorBgLayout,
            paddingLeft: token.padding,
            paddingRight: token.padding

        }}>
            {children}
        </div>

    );
};
export default memo(StickyBar) ;