var bus = {};
class Bus {

    constructor() {

    }

    on(key, handler) {
     
        if (bus[key] === undefined) {
            bus[key] = []
        }
        bus[key]?.push(handler);
        return () => {
            this.off(key, handler)
        }
    }
    off(key, handler) {
      
        const index = bus[key]?.indexOf(handler) ?? -1
        bus[key]?.splice(index >>> 0, 1)
    }
    offAll(key) {

        bus[key] = [];
    }
    emit(key, payload) {
        bus[key]?.forEach((fn) => {

            try {
                fn(payload)

            } catch (e) {
                // config?.onError(e)
                console.error(e)
            }
        })
    }
    once(key, handler) {

    }

}
var bus2 = new Bus();
export default bus2;