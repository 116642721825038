import { Row, Table } from "antd";
import { BellFilled, BellOutlined } from "@ant-design/icons";
import { getQuantityItem } from "common/get_quantity_item";
import { getAlertItem } from "common/get_alert_item";
import { getStockItem } from "common/get_stock_item";
import { useAppContext } from "context/app";

function ListFolders({ item = {}, lstFolder = [], currentFolder }) {
    const { showAlertModal } = useAppContext();

    const columns = [
        {
            title: "Folder Name",
            dataIndex: "name",
            key: "name",
            fixed: "left",
        },
        {
            title: "On Hand Quantity",
            dataIndex: "",
            key: "quantity",
            fixed: "left",
            render: (text, record) => {
                return getQuantityItem(item, record.id);
            },
        },
        {
            title: "Min Level",
            dataIndex: "",
            key: "min_level",
            fixed: "left",
            render: (text, record) => {
                const alert = getAlertItem(item, record.id);
                return alert ? alert.quantity : "";
            },
        },
        {
            title: "Stock Alert",
            dataIndex: "",
            key: "stock_alert",
            fixed: "left",
            render: (text, record) => {
                const alert = getAlertItem(item, record.id);
                return (
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            showAlertModal([item], record.id);
                        }}
                    >
                        {alert ? <BellOutlined /> : <BellFilled />}
                    </div>
                );
            },
        },
        {
            title: "Total Amount",
            dataIndex: "total_amount",
            key: "total_amount",
            fixed: "left",
            render: (text, record) => {
                const stock = getStockItem(item, record.id);
                return stock ? stock.total_amount : "";
            },
        },
    ];

    return <Table columns={columns} dataSource={lstFolder}></Table>;
}

export default ListFolders;
