import { api_url } from "config"
import axios from 'axios';

console.log("api_url", api_url)
class BaseService {

    constructor() {

        var api = axios.create({
            baseURL: api_url,
            headers: { 'X-Custom-Header': 'foobar' }
        });


        api.interceptors.response.use(
            (response) => {
                var { data } = response;
                if (data.code == 1)
                    return data.data;
                console.error("api error", data.error)
                return Promise.reject(data.error);
            },
            (error) => {

                return Promise.reject(error);
            }
        );


        this._api = api;
    }


}
export default BaseService;