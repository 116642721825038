import { useEffect, memo } from "react";
import { Button, Flex, Space, Divider } from "antd";
import Filter from "./Filter"
import Statistic from "./Statistic"
import List from "./List"
import ItemEditor from "components/item/Add"
const AddItemPage = () => {
    return (
        <div>
       
            <ItemEditor data={{}}></ItemEditor>
        
        </div>
    )
}




export default memo(AddItemPage);