import { TagOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import AddOrEditTagModal from "components/Tag/AddOrEditTagModal";
import { useState } from "react";
import TagService from "services/tag";

function Tag({ item, selectedTag, onChange }) {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleTagEdit = (data) => {
    onChange(data, "edit");
    setModalOpen(false);
  };

  const confirm = async (data) => {
    const tagService = new TagService();
    const result = await tagService.remove(data.id);

    if (result) {
      onChange(data);
    }
  };

  const cancel = (e) => {
    console.log(e);
  };

  return (
    <>
      <AddOrEditTagModal visible={isModalOpen} value={item} onClose={() => setModalOpen(false)} onEdit={handleTagEdit} />
      <div key={item.id} className={item === selectedTag ? "item-tag active" : "item-tag"}>
        <TagOutlined style={item === selectedTag ? { color: item.color } : {}} />
        <span style={{ maxWidth: "92px", display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 3, overflow: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word" }}>
          {item.title}
        </span>

        <div className="icon-action">
          <EditOutlined style={{ cursor: "pointer", marginRight: "16px" }} onClick={() => setModalOpen(true)} />
          <Popconfirm title="Delete the tag" description="Are you sure to delete this tag?" onConfirm={() => confirm(item)} onCancel={cancel} okText="Yes" cancelText="No">
            <DeleteOutlined style={{ cursor: "pointer" }} />
          </Popconfirm>
        </div>
      </div>
    </>
  );
}

export default Tag;
