function AlbumPreviewSVG() {
  return (
    <svg width="294" height="426" viewBox="0 0 294 426" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d___DuQOTkQZ)">
        <rect x="16" y="12" width="262" height="394" rx="4.67059" fill="white"></rect>
        <rect x="16.3892" y="12.3892" width="261.222" height="393.222" rx="4.28137" stroke="#E9E9F4" stroke-width="0.778431"></rect>
      </g>
      <g clip-path="url(#clip0___DuQOTkQZ)">
        <rect x="41" y="82" width="212" height="83" rx="1" fill="#FCFCFF"></rect>
        <circle opacity="0.3" cx="242" cy="156" r="60" transform="rotate(-90 242 156)" fill="url(#paint0_linear___DuQOTkQZ)"></circle>
        <circle opacity="0.85" cx="259" cy="156" r="60" transform="rotate(-90 259 156)" fill="url(#paint1_linear___DuQOTkQZ)"></circle>
        <rect x="49" y="145" width="36" height="4" rx="1" fill="#EDEDF6"></rect>
        <rect x="49" y="153" width="30" height="4" rx="1" fill="#EDEDF6"></rect>
      </g>
      <rect x="41" y="37" width="62" height="10" rx="1" fill="#A5A9BE"></rect>
      <path d="M87 37H102.611C102.826 37 103 37.1743 103 37.3892V46.6108C103 46.8257 102.826 47 102.611 47H87V37Z" fill="#EDEDF6"></path>
      <path
        d="M247.285 37.425V42.6251C247.285 42.8598 247.105 43.0502 246.883 43.0502H245.224C245.002 43.0502 244.823 42.8598 244.823 42.6251V40.8774C244.823 40.6427 244.643 40.4523 244.421 40.4523H242.762C242.54 40.4523 242.36 40.6427 242.36 40.8774V42.6336C242.36 42.8683 242.54 43.0586 242.762 43.0586H244.421C244.643 43.0586 244.823 43.249 244.823 43.4837V45.2313C244.823 45.466 245.002 45.6563 245.224 45.6563H249.414C249.636 45.6563 249.816 45.466 249.816 45.2313V43.4752C249.816 43.2405 249.996 43.0502 250.217 43.0502H252.599C252.821 43.0502 253 42.8598 253 42.6251V37.425C253 37.1903 252.821 37 252.599 37H247.686C247.465 37 247.285 37.1902 247.285 37.425Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M235.573 48.3017H239.497C239.718 48.3017 239.898 48.1114 239.898 47.8766V46.1204C239.898 45.8857 239.718 45.6953 239.497 45.6953H235.573C235.352 45.6953 235.172 45.8857 235.172 46.1204V47.8766C235.172 48.1114 235.352 48.3017 235.573 48.3017Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M241.959 37.0352H238.859C238.637 37.0352 238.457 37.2255 238.457 37.4602V39.2165C238.457 39.4512 238.637 39.6415 238.859 39.6415H241.959C242.181 39.6415 242.36 39.4512 242.36 39.2165V37.4602C242.36 37.2254 242.181 37.0352 241.959 37.0352Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M244.906 53.0876V51.3313C244.906 51.0966 244.726 50.9062 244.504 50.9062H242.845C242.623 50.9062 242.443 51.0966 242.443 51.3313V53.0876C242.443 53.3223 242.623 53.5126 242.845 53.5126H244.504C244.726 53.5126 244.906 53.3223 244.906 53.0876Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M249.816 50.9032V50.68V48.7219C249.816 48.4872 249.636 48.2969 249.414 48.2969H245.307C245.085 48.2969 244.905 48.4872 244.905 48.7219V50.4781C244.905 50.7128 245.085 50.9032 245.307 50.9032H246.751C246.973 50.9032 247.153 51.0935 247.153 51.3282V52.9486V53.5094V58.5737C247.153 58.8084 247.332 58.9987 247.554 58.9987H252.467C252.688 58.9987 252.868 58.8084 252.868 58.5737V53.3736C252.868 53.1389 252.688 52.9486 252.467 52.9486H250.217C249.996 52.9486 249.816 52.7582 249.816 52.5235V50.9032H249.816Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M249.815 46.1555V47.9117C249.815 48.1464 249.995 48.3368 250.217 48.3368H251.876C252.098 48.3368 252.278 48.1464 252.278 47.9117V46.1555C252.278 45.9208 252.098 45.7305 251.876 45.7305H250.217C249.995 45.7305 249.815 45.9207 249.815 46.1555Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M233.864 50.9032H234.77C234.992 50.9032 235.172 50.7128 235.172 50.4781V48.7219C235.172 48.4872 234.992 48.2969 234.77 48.2969H233.111C232.889 48.2969 232.709 48.4872 232.709 48.7219V49.9172C232.709 50.1519 232.53 50.3423 232.308 50.3423H231.402C231.18 50.3423 231 50.5326 231 50.7673V52.9487V53.6443V58.5738C231 58.8085 231.18 58.9988 231.402 58.9988H236.314C236.536 58.9988 236.716 58.8085 236.716 58.5738V53.3737C236.716 53.139 236.536 52.9487 236.314 52.9487H233.864C233.642 52.9487 233.462 52.7583 233.462 52.5236V51.3283C233.462 51.0935 233.642 50.9032 233.864 50.9032Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M241.64 53.5129V51.3316C241.64 51.0969 241.819 50.9065 242.041 50.9065C242.263 50.9065 242.443 50.7162 242.443 50.4815V48.7618C242.443 48.5271 242.623 48.3368 242.844 48.3368H244.42C244.642 48.3368 244.822 48.1464 244.822 47.9117V46.1555C244.822 45.9208 244.642 45.7305 244.42 45.7305H242.761C242.54 45.7305 242.36 45.9208 242.36 46.1555V47.8752C242.36 48.1099 242.18 48.3002 241.958 48.3002H240.382C240.16 48.3002 239.981 48.4906 239.981 48.7253V50.4815C239.981 50.7162 239.801 50.9065 239.579 50.9065H237.837C237.615 50.9065 237.436 51.0969 237.436 51.3316V53.0879C237.436 53.3226 237.615 53.5129 237.837 53.5129H238.776C238.998 53.5129 239.178 53.7032 239.178 53.9379V55.6941C239.178 55.9288 239.357 56.1192 239.579 56.1192H241.222C241.444 56.1192 241.624 56.3095 241.624 56.5442V58.3005C241.624 58.5352 241.803 58.7256 242.025 58.7256H246.192C246.414 58.7256 246.594 58.5352 246.594 58.3005V56.5442C246.594 56.3095 246.414 56.1192 246.192 56.1192H242.041C241.82 56.1192 241.64 55.9288 241.64 55.6941V53.5129H241.64Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M242.361 45.2712V43.5149C242.361 43.2802 242.181 43.0898 241.959 43.0898H240.3C240.078 43.0898 239.898 43.2802 239.898 43.5149V45.2712C239.898 45.5059 240.078 45.6962 240.3 45.6962H241.959C242.181 45.6962 242.361 45.506 242.361 45.2712Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M233.111 45.6955H234.77C234.992 45.6955 235.172 45.5052 235.172 45.2705V43.5142C235.172 43.2795 235.352 43.0891 235.573 43.0891H236.457H238.776C238.998 43.0891 239.178 42.8988 239.178 42.6641V40.9079C239.178 40.6732 238.998 40.4828 238.776 40.4828H237.117C236.895 40.4828 236.716 40.2925 236.716 40.0578V37.4641C236.716 37.2294 236.536 37.0391 236.314 37.0391H231.402C231.18 37.0391 231 37.2293 231 37.4641V42.6642C231 42.8989 231.18 43.0892 231.402 43.0892H232.308C232.53 43.0892 232.709 43.2796 232.709 43.5143V45.2706C232.71 45.5053 232.889 45.6955 233.111 45.6955Z"
        fill="#A5A9BE"
      ></path>
      <rect x="41" y="222" width="46" height="8" rx="1" fill="#A5A9BE"></rect>
      <rect opacity="0.85" x="41" y="244" width="46" height="8" rx="1" fill="#DCDBEC"></rect>
      <rect opacity="0.85" x="41" y="266" width="46" height="8" rx="1" fill="#DCDBEC"></rect>
      <rect opacity="0.85" x="41" y="288" width="46" height="8" rx="1" fill="#DCDBEC"></rect>
      <rect opacity="0.85" x="41" y="310" width="46" height="8" rx="1" fill="#DCDBEC"></rect>
      <rect opacity="0.85" x="41" y="332" width="46" height="8" rx="1" fill="#DCDBEC"></rect>
      <rect x="119" y="222" width="134" height="8" rx="1" fill="#EDEDF6"></rect>
      <rect x="119" y="244" width="134" height="8" rx="1" fill="#EDEDF6"></rect>
      <rect x="119" y="266" width="134" height="8" rx="1" fill="#EDEDF6"></rect>
      <rect x="119" y="288" width="134" height="8" rx="1" fill="#EDEDF6"></rect>
      <rect x="119" y="310" width="134" height="8" rx="1" fill="#EDEDF6"></rect>
      <rect x="119" y="332" width="68" height="8" rx="1" fill="#EDEDF6"></rect>
      <rect x="102.306" y="222.133" width="0.770588" height="115.882" fill="#F3F3FD"></rect>
      <rect x="41" y="194" width="132" height="9.99984" rx="1" fill="#A5A9BE"></rect>
      <path d="M157 194H172.611C172.826 194 173 194.174 173 194.389V203.611C173 203.826 172.826 204 172.611 204H157V194Z" fill="#EDEDF6"></path>
      <path d="M75 222H86.2216C86.6515 222 87 222.349 87 222.778V229.222C87 229.651 86.6515 230 86.2216 230H75V222Z" fill="#EDEDF6"></path>
      <defs>
        <filter id="filter0_d___DuQOTkQZ" x="0.431373" y="0.323529" width="293.137" height="425.137" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="3.89216"></feOffset>
          <feGaussianBlur stdDeviation="7.78431"></feGaussianBlur>
          <feColorMatrix type="matrix" values="0 0 0 0 0.235294 0 0 0 0 0.25098 0 0 0 0 0.345098 0 0 0 0.03 0"></feColorMatrix>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
        </filter>
        <linearGradient id="paint0_linear___DuQOTkQZ" x1="242" y1="96" x2="242" y2="216" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint1_linear___DuQOTkQZ" x1="259" y1="96" x2="259" y2="216" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <clipPath id="clip0___DuQOTkQZ">
          <rect width="212" height="83" fill="white" transform="translate(41 82)"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default AlbumPreviewSVG;
