import BaseService from "services/base";
import _ from "lodash";

class LabelService extends BaseService {
  /**
   *
   * @param {Array[item]} data  [{id:"item 0-1", name:'item 001', code:'0001', labels:5}]
   * @param {PrintSetting} option :{printType:"qr"/"bar", }
   * @returns {{fileUrl}} url of pdf file to print
   */
  print = async (items, options) => {
    // var image = this._api.post("image/upload", formdata)
    //return image;

    var url = await this._api.post("label/print", { items, options });
    return url;
  };

  getLabelTypes = async () => {
    return [
      {
        value: "qr",
        label: "QR Code",
      },
      {
        value: "barcode",
        label: "Barcode",
      },
    ];
  };

  getPaperSizes = async () => {
    return [
      {
        value: "us_letter",
        label: "US Letter (8.5in x 11in)",
        size_labels: [
          {
            // Avery 5160
            value: "avery_5160",
            label: "Avery 5160 (2 5/8in x 1in)",
            width: 252,
            height: 96,
            fontSizeItem: 10,
            fontSizeField: 8,
            fontSizeImage: 35,
            layout: "horizontal",
            perSheet: 30,
            marginHorizontal: 18,
            marginVertical: 48,
            columnGap: 12,
            borderRadius: 10,
          },
          {
            // Avery 5164
            value: "avery 5164",
            label: "Avery 5164 (3 1/3in x 4in)",
            width: 384,
            height: 320,
            fontSizeItem: 16,
            fontSizeField: 13,
            fontSizeImage: 80,
            layout: "vertical",
            perSheet: 6,
            marginHorizontal: 15,
            marginVertical: 48,
            columnGap: 18,
            borderRadius: 18,
          },
          {
            // Avery 5163
            value: "avery_5163",
            label: "Avery 5163 (4in x 2in)",
            width: 384,
            height: 192,
            fontSizeItem: 12,
            fontSizeField: 10,
            fontSizeImage: 50,
            layout: "horizontal",
            perSheet: 10,
            marginHorizontal: 15,
            marginVertical: 48,
            columnGap: 18,
            borderRadius: 20,
          },
          {
            // Avery 5292
            value: "avery_5292",
            label: "Avery 5292 (4in x 6in)",
            width: 384,
            height: 576,
            fontSizeItem: 35,
            fontSizeField: 25,
            fontSizeImage: 100,
            layout: "vertical",
            perSheet: 1,
            marginHorizontal: 0,
            marginVertical: 0,
            columnGap: 0,
            borderRadius: 0,
          },
        ],
      },
      {
        value: "a4_sheet",
        label: "A4 Sheet (21.0cm x 29.7cm)",
        size_labels: [
          {
            value: "avery_l7651",
            label: "Avery L7651 (38.1 x 21.17 mm)",
            width: 144,
            height: 79.68,
            fontSizeItem: 7,
            fontSizeField: 5,
            fontSizeImage: 30,
            layout: "horizontal",
            perSheet: 65,
            marginHorizontal: 17.28,
            marginVertical: 41.28,
            columnGap: 9.6,
            borderRadius: 12,
          },
          {
            value: "avery_l7671",
            label: "Avery L7671 (76.2mm x 46.4 mm)",
            width: 288,
            height: 175.68,
            fontSizeItem: 12,
            fontSizeField: 10,
            fontSizeImage: 60,
            layout: "horizontal",
            perSheet: 12,
            marginHorizontal: 103.68,
            marginVertical: 35,
            columnGap: 9.6,
            borderRadius: 12,
          },
        ],
      },
      {
        value: "thermal",
        label: "Thermal (75mm x 50mm)",
        size_labels: [
          {
            value: "thermal_80",
            label: "Thermal 80 (75mm x 50mm)",
            width: 283,
            height: 181,
            fontSizeItem: 12,
            fontSizeField: 10,
            fontSizeImage: 60,
            layout: "horizontal",
            perSheet: 1,
            marginHorizontal: 4,
            marginVertical: 4,
            columnGap: 0,
            borderRadius: 8,
          },
        ],
      },
    ];
  };
}

export default LabelService;
