import React, { useState, memo, useEffect, useMemo } from 'react';
import { Input, Tree, theme } from 'antd';
import FolderService from "services/folder";
import * as Icon from '@ant-design/icons';
import _ from "lodash"
import { useItemsScreenContext } from 'pages/items';
import { useAppContext } from "context/app";
const folderService = new FolderService();

const { useToken } = theme;

const FolderPicker = ({ value, onChange, onPathChange }) => {
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const { token } = useToken();
    const folders = useAppContext().folderList;
    const { currentFolder, setCurrentFolder, currentFolderPath, setCurrentFolderPath } = useItemsScreenContext();

    useEffect(() => {
        loadTreeData();

    }, [folders])

    useEffect(() => {
        var tree = toTreeData([...folders]);
        const parentKeys = findParentKeys(tree, currentFolder);
        setExpandedKeys([...parentKeys, currentFolder]);
        setSelectedKeys([currentFolder])
    }, [folders, currentFolder]);


    const findParentKeys = (nodes, targetKey, parents = []) => {
        for (let node of nodes) {
            if (node.key === targetKey) {
                return parents;
            }
            if (node.children) {
                const foundParents = findParentKeys(node.children, targetKey, [...parents, node.key]);
                if (foundParents.length > 0) {
                    return foundParents;
                }
            }
        }
        return [];
    };

    const loadTreeData = async () => {

        // var allFolder = await folderService.list();
        // setFolders(allFolder);
        var tree = toTreeData([...folders]);

        // var tree2 = toQuickAccessTree([...folders]);
        // setTreeDataQuickAccess(tree2)
        // setTreeData(tree);
    }

    const toTreeData = (lst) => {
        if (lst.length == 0) {
            return [];
        }

        var root = lst.find(x => x.is_root);
        var arrarLoop = [];
        var tree = { value: root.id, title: root.name, children: [], id: root.id, key: root.id, }
        arrarLoop.push(tree);
        while (arrarLoop.length > 0) {
            var node = arrarLoop.pop();

            var children = lst.filter(x => x.parent_id === node.id).map(x => ({ value: x.id, title: x.name, id: x.id, children: [], key: x.id }));

            node.children = children;
            node.children.forEach(childNode => {
                arrarLoop.push(childNode);
            });

        }
        // tree.id='root'

        return [tree];
    }

    const toQuickAccessTree = (lst) => {
        var root = lst.find(x => x.is_root);
        var arrarLoop = [];
        var tree = { value: root.id, title: 'Quick Access', children: [], id: root.id, key: root.id, }
        arrarLoop.push(tree);
        while (arrarLoop.length > 0) {
            var node = arrarLoop.pop();

            var children = lst.filter(x => x.parent_id === node.id).map(x => ({ value: x.id, title: x.name, id: x.id, children: [], key: x.id }));

            node.children = children;
            node.children.forEach(childNode => {
                arrarLoop.push(childNode);
            });

        }

        return [tree];
    }


    const onSelect = (key) => {

        if (key && key.length > 0) {
            setCurrentFolder(key[0]);
        }

    }

    const onExpand = (keys) => {
        setExpandedKeys(keys);
    }

    const getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some((item) => item.key === key)) {
                    parentKey = node.key;
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    const handleSearch = (e) => {
        const { value } = e.target;
        const newExpandedKeys = folders
            .map((item) => {
                if (item.name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    return findParentKeys(toTreeData(folders), item.id);
                }
                return [];
            })
            .flat()
            .filter((key, index, self) => self.indexOf(key) === index);

        setExpandedKeys(newExpandedKeys);
        setSearchValue(value);
    };

    const treeData = useMemo(() => {
        if (searchValue === "") {
            var tree = toTreeData([...folders]);
            const parentKeys = findParentKeys(tree, currentFolder);
            setExpandedKeys([...parentKeys, currentFolder]);
            setSelectedKeys([currentFolder])
            return tree;
        }

        const normalizeString = (str) => str.toLowerCase();
        const loop = (data) =>
            data.map((item) => {
                const strTitle = item.title;
                const normalizedTitle = normalizeString(strTitle);
                const normalizedSearchValue = normalizeString(searchValue);

                const index = normalizedTitle.indexOf(normalizedSearchValue);
                const beforeStr = strTitle.substring(0, index);
                const highlightedStr = strTitle.substring(index, index + searchValue.length);
                const afterStr = strTitle.slice(index + searchValue.length);
                const title =
                    index > -1 ? (
                        <span key={item.key}>
                            {beforeStr}
                            <span style={{ color: token.colorError }}>{highlightedStr}</span>
                            {afterStr}
                        </span>
                    ) : (
                        <span key={item.key}>{strTitle}</span>
                    );

                if (item.children) {
                    return {
                        id: item.id,
                        value: item.id,
                        title,
                        key: item.key,
                        children: loop(item.children),
                    };
                }

                return {
                    title,
                    key: item.key,
                    id: item.id,
                    value: item.id,
                };
            });

        return loop(toTreeData(folders));
    }, [searchValue, folders]);

    return (
        <div>


            {/* <Tree
                style={{
                    width: 400,
                }}
                value={value}
                dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                }}
                suffixIcon={<Icon.DownOutlined size={64} />}
                treeData={treeDataQuickAccess}
                placeholder="Please select"
                showLine={true}
                defaultExpandAll={true}

                onSelect={onSelect}
            /> */}
            <div>
                <Input style={{ margin: "10px 0 12px" }} placeholder="Search" addonBefore={<Icon.SearchOutlined />} onChange={handleSearch} />

            </div>
            <div style={{ backgroundColor: token.colorBgContainer, height: "calc(100vh - 54px)", overflow:"scroll"}}>



                <Tree
                    style={{
                        width: "100%",
                
                    }}
                    value={value}
                    dropdownStyle={{
                        height: "100%",
                        overflow: 'auto',
                    }}
                    suffixIcon={<Icon.DownOutlined size={64} />}
                    treeData={treeData}
                    placeholder="Please select"
                    showLine={true}
                    defaultExpandAll={true}
                    expandedKeys={expandedKeys}
                    selectedKeys={selectedKeys}
                    onExpand={onExpand}
                    onSelect={onSelect}
                //expandedKeys={['d3984b5b-7d8d-4a20-8a4e-6e2e278c0b57']}
                />
            </div>
        </div>
    );
};
export default memo(FolderPicker);