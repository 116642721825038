function CompactPreviewSVG() {
  return (
    <svg width="294" height="426" viewBox="0 0 294 426" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d___TwtmIViB)">
        <rect x="16" y="12" width="262" height="394" rx="5" fill="white"></rect>
        <rect x="16.3853" y="12.3853" width="261.229" height="393.229" rx="4.61471" stroke="#E9E9F4" stroke-width="0.770588"></rect>
      </g>
      <rect x="42.25" y="146.25" width="210.5" height="0.5" stroke="#E9E9F4" stroke-width="0.5" stroke-dasharray="2.31 2.31"></rect>
      <rect x="147.75" y="81.25" width="294.5" height="0.499994" transform="rotate(90 147.75 81.25)" stroke="#E9E9F4" stroke-width="0.499994" stroke-dasharray="2.31 2.31"></rect>
      <rect x="42.25" y="228.25" width="210.5" height="0.5" stroke="#E9E9F4" stroke-width="0.5" stroke-dasharray="2.31 2.31"></rect>
      <rect x="42.25" y="310.25" width="210.5" height="0.5" stroke="#E9E9F4" stroke-width="0.5" stroke-dasharray="2.31 2.31"></rect>
      <rect x="86" y="81" width="53" height="7.99991" rx="1" fill="#A5A9BE"></rect>
      <path d="M130 81H138.229C138.655 81 139 81.345 139 81.7706V88.2293C139 88.6549 138.655 88.9999 138.229 88.9999H130V81Z" fill="#EDEDF6"></path>
      <rect x="86" y="98" width="13" height="5" rx="1" fill="#A5A9BE"></rect>
      <rect x="103" y="98" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <path d="M95 98H98.2294C98.655 98 99 98.345 99 98.7706V102.229C99 102.655 98.655 103 98.2294 103H95V98Z" fill="#EDEDF6"></path>
      <rect x="86" y="107" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="103" y="107" width="30" height="5" rx="0.770588" fill="#EDEDF6"></rect>
      <rect x="86" y="116" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="103" y="116" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <rect x="86" y="125" width="15" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="105" y="125" width="16" height="5" rx="1" fill="#EDEDF6"></rect>
      <g clip-path="url(#clip0___TwtmIViB)">
        <rect x="41" y="81" width="36" height="36" rx="1" fill="#FCFCFF"></rect>
        <ellipse opacity="0.3" cx="79" cy="123" rx="36" ry="36" transform="rotate(-90 79 123)" fill="url(#paint0_linear___TwtmIViB)"></ellipse>
        <ellipse opacity="0.85" cx="89" cy="123" rx="36" ry="36" transform="rotate(-90 89 123)" fill="url(#paint1_linear___TwtmIViB)"></ellipse>
      </g>
      <rect x="200" y="81" width="53" height="7.99991" rx="1" fill="#A5A9BE"></rect>
      <path d="M244 81H252.229C252.655 81 253 81.345 253 81.7706V88.2293C253 88.6549 252.655 88.9999 252.229 88.9999H244V81Z" fill="#EDEDF6"></path>
      <rect x="200" y="98" width="13" height="5" rx="1" fill="#A5A9BE"></rect>
      <rect x="217" y="98" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <path d="M209 98H212.229C212.655 98 213 98.345 213 98.7706V102.229C213 102.655 212.655 103 212.229 103H209V98Z" fill="#EDEDF6"></path>
      <rect x="200" y="107" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="217" y="107" width="30" height="5" rx="0.770588" fill="#EDEDF6"></rect>
      <rect x="200" y="116" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="217" y="116" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <rect x="200" y="125" width="15" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="219" y="125" width="16" height="5" rx="1" fill="#EDEDF6"></rect>
      <g clip-path="url(#clip1___TwtmIViB)">
        <rect x="156" y="81" width="36" height="36" rx="1" fill="#FCFCFF"></rect>
        <ellipse opacity="0.3" cx="194" cy="123" rx="36" ry="36" transform="rotate(-90 194 123)" fill="url(#paint2_linear___TwtmIViB)"></ellipse>
        <ellipse opacity="0.85" cx="204" cy="123" rx="36" ry="36" transform="rotate(-90 204 123)" fill="url(#paint3_linear___TwtmIViB)"></ellipse>
      </g>
      <rect x="86" y="163" width="53" height="7.99991" rx="1" fill="#A5A9BE"></rect>
      <path d="M130 163H138.229C138.655 163 139 163.345 139 163.771V170.229C139 170.655 138.655 171 138.229 171H130V163Z" fill="#EDEDF6"></path>
      <rect x="86" y="180" width="13" height="5" rx="1" fill="#A5A9BE"></rect>
      <rect x="103" y="180" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <path d="M95 180H98.2294C98.655 180 99 180.345 99 180.771V184.229C99 184.655 98.655 185 98.2294 185H95V180Z" fill="#EDEDF6"></path>
      <rect x="86" y="189" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="103" y="189" width="30" height="5" rx="0.770588" fill="#EDEDF6"></rect>
      <rect x="86" y="198" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="103" y="198" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <rect x="86" y="207" width="15" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="105" y="207" width="16" height="5" rx="1" fill="#EDEDF6"></rect>
      <g clip-path="url(#clip2___TwtmIViB)">
        <rect x="41" y="163" width="36" height="36" rx="1" fill="#FCFCFF"></rect>
        <ellipse opacity="0.3" cx="79" cy="205" rx="36" ry="36" transform="rotate(-90 79 205)" fill="url(#paint4_linear___TwtmIViB)"></ellipse>
        <ellipse opacity="0.85" cx="89" cy="205" rx="36" ry="36" transform="rotate(-90 89 205)" fill="url(#paint5_linear___TwtmIViB)"></ellipse>
      </g>
      <rect x="200" y="163" width="53" height="7.99991" rx="1" fill="#A5A9BE"></rect>
      <path d="M244 163H252.229C252.655 163 253 163.345 253 163.771V170.229C253 170.655 252.655 171 252.229 171H244V163Z" fill="#EDEDF6"></path>
      <rect x="200" y="180" width="13" height="5" rx="1" fill="#A5A9BE"></rect>
      <rect x="217" y="180" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <path d="M209 180H212.229C212.655 180 213 180.345 213 180.771V184.229C213 184.655 212.655 185 212.229 185H209V180Z" fill="#EDEDF6"></path>
      <rect x="200" y="189" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="217" y="189" width="30" height="5" rx="0.770588" fill="#EDEDF6"></rect>
      <rect x="200" y="198" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="217" y="198" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <rect x="200" y="207" width="15" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="219" y="207" width="16" height="5" rx="1" fill="#EDEDF6"></rect>
      <g clip-path="url(#clip3___TwtmIViB)">
        <rect x="156" y="163" width="36" height="36" rx="1" fill="#FCFCFF"></rect>
        <ellipse opacity="0.3" cx="194" cy="205" rx="36" ry="36" transform="rotate(-90 194 205)" fill="url(#paint6_linear___TwtmIViB)"></ellipse>
        <ellipse opacity="0.85" cx="204" cy="205" rx="36" ry="36" transform="rotate(-90 204 205)" fill="url(#paint7_linear___TwtmIViB)"></ellipse>
      </g>
      <rect x="86" y="245" width="53" height="7.99991" rx="1" fill="#A5A9BE"></rect>
      <path d="M130 245H138.229C138.655 245 139 245.345 139 245.771V252.229C139 252.655 138.655 253 138.229 253H130V245Z" fill="#EDEDF6"></path>
      <rect x="86" y="262" width="13" height="5" rx="1" fill="#A5A9BE"></rect>
      <rect x="103" y="262" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <path d="M95 262H98.2294C98.655 262 99 262.345 99 262.771V266.229C99 266.655 98.655 267 98.2294 267H95V262Z" fill="#EDEDF6"></path>
      <rect x="86" y="271" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="103" y="271" width="30" height="5" rx="0.770588" fill="#EDEDF6"></rect>
      <rect x="86" y="280" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="103" y="280" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <rect x="86" y="289" width="15" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="105" y="289" width="16" height="5" rx="1" fill="#EDEDF6"></rect>
      <g clip-path="url(#clip4___TwtmIViB)">
        <rect x="41" y="245" width="36" height="36" rx="1" fill="#FCFCFF"></rect>
        <ellipse opacity="0.3" cx="79" cy="287" rx="36" ry="36" transform="rotate(-90 79 287)" fill="url(#paint8_linear___TwtmIViB)"></ellipse>
        <ellipse opacity="0.85" cx="89" cy="287" rx="36" ry="36" transform="rotate(-90 89 287)" fill="url(#paint9_linear___TwtmIViB)"></ellipse>
      </g>
      <rect x="200" y="245" width="53" height="7.99991" rx="1" fill="#A5A9BE"></rect>
      <path d="M244 245H252.229C252.655 245 253 245.345 253 245.771V252.229C253 252.655 252.655 253 252.229 253H244V245Z" fill="#EDEDF6"></path>
      <rect x="200" y="262" width="13" height="5" rx="1" fill="#A5A9BE"></rect>
      <rect x="217" y="262" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <path d="M209 262H212.229C212.655 262 213 262.345 213 262.771V266.229C213 266.655 212.655 267 212.229 267H209V262Z" fill="#EDEDF6"></path>
      <rect x="200" y="271" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="217" y="271" width="30" height="5" rx="0.770588" fill="#EDEDF6"></rect>
      <rect x="200" y="280" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="217" y="280" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <rect x="200" y="289" width="15" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="219" y="289" width="16" height="5" rx="1" fill="#EDEDF6"></rect>
      <g clip-path="url(#clip5___TwtmIViB)">
        <rect x="156" y="245" width="36" height="36" rx="1" fill="#FCFCFF"></rect>
        <ellipse opacity="0.3" cx="194" cy="287" rx="36" ry="36" transform="rotate(-90 194 287)" fill="url(#paint10_linear___TwtmIViB)"></ellipse>
        <ellipse opacity="0.85" cx="204" cy="287" rx="36" ry="36" transform="rotate(-90 204 287)" fill="url(#paint11_linear___TwtmIViB)"></ellipse>
      </g>
      <rect x="86" y="327" width="53" height="7.99991" rx="1" fill="#A5A9BE"></rect>
      <path d="M130 327H138.229C138.655 327 139 327.345 139 327.771V334.229C139 334.655 138.655 335 138.229 335H130V327Z" fill="#EDEDF6"></path>
      <rect x="86" y="344" width="13" height="5" rx="1" fill="#A5A9BE"></rect>
      <rect x="103" y="344" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <path d="M95 344H98.2294C98.655 344 99 344.345 99 344.771V348.229C99 348.655 98.655 349 98.2294 349H95V344Z" fill="#EDEDF6"></path>
      <rect x="86" y="353" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="103" y="353" width="30" height="5" rx="0.770588" fill="#EDEDF6"></rect>
      <rect x="86" y="362" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="103" y="362" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <rect x="86" y="371" width="15" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="105" y="371" width="16" height="5" rx="1" fill="#EDEDF6"></rect>
      <g clip-path="url(#clip6___TwtmIViB)">
        <rect x="41" y="327" width="36" height="36" rx="1" fill="#FCFCFF"></rect>
        <ellipse opacity="0.3" cx="79" cy="369" rx="36" ry="36" transform="rotate(-90 79 369)" fill="url(#paint12_linear___TwtmIViB)"></ellipse>
        <ellipse opacity="0.85" cx="89" cy="369" rx="36" ry="36" transform="rotate(-90 89 369)" fill="url(#paint13_linear___TwtmIViB)"></ellipse>
      </g>
      <rect x="200" y="327" width="53" height="7.99991" rx="1" fill="#A5A9BE"></rect>
      <path d="M244 327H252.229C252.655 327 253 327.345 253 327.771V334.229C253 334.655 252.655 335 252.229 335H244V327Z" fill="#EDEDF6"></path>
      <rect x="200" y="344" width="13" height="5" rx="1" fill="#A5A9BE"></rect>
      <rect x="217" y="344" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <path d="M209 344H212.229C212.655 344 213 344.345 213 344.771V348.229C213 348.655 212.655 349 212.229 349H209V344Z" fill="#EDEDF6"></path>
      <rect x="200" y="353" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="217" y="353" width="30" height="5" rx="0.770588" fill="#EDEDF6"></rect>
      <rect x="200" y="362" width="13" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="217" y="362" width="30" height="5" rx="1" fill="#EDEDF6"></rect>
      <rect x="200" y="371" width="15" height="5" rx="1" fill="#DCDBEC"></rect>
      <rect x="219" y="371" width="16" height="5" rx="1" fill="#EDEDF6"></rect>
      <g clip-path="url(#clip7___TwtmIViB)">
        <rect x="156" y="327" width="36" height="36" rx="1" fill="#FCFCFF"></rect>
        <ellipse opacity="0.3" cx="194" cy="369" rx="36" ry="36" transform="rotate(-90 194 369)" fill="url(#paint14_linear___TwtmIViB)"></ellipse>
        <ellipse opacity="0.85" cx="204" cy="369" rx="36" ry="36" transform="rotate(-90 204 369)" fill="url(#paint15_linear___TwtmIViB)"></ellipse>
      </g>
      <ellipse cx="42.5231" cy="38.5" rx="1.52311" ry="1.5" fill="#DBDBEC"></ellipse>
      <ellipse cx="48.0006" cy="38.5" rx="1.52311" ry="1.5" fill="#DBDBEC"></ellipse>
      <ellipse cx="53.4772" cy="38.5" rx="1.52311" ry="1.5" fill="#DBDBEC"></ellipse>
      <rect x="217" y="37" width="36" height="4" rx="1" fill="#EDEDF6"></rect>
      <rect x="225" y="45" width="28" height="4" rx="1" fill="#EDEDF6"></rect>
      <defs>
        <filter id="filter0_d___TwtmIViB" x="0.588236" y="0.441177" width="292.824" height="424.824" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="3.85294"></feOffset>
          <feGaussianBlur stdDeviation="7.70588"></feGaussianBlur>
          <feColorMatrix type="matrix" values="0 0 0 0 0.235294 0 0 0 0 0.25098 0 0 0 0 0.345098 0 0 0 0.03 0"></feColorMatrix>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
        </filter>
        <linearGradient id="paint0_linear___TwtmIViB" x1="79" y1="87" x2="79" y2="159" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint1_linear___TwtmIViB" x1="89" y1="87" x2="89" y2="159" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint2_linear___TwtmIViB" x1="194" y1="87" x2="194" y2="159" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint3_linear___TwtmIViB" x1="204" y1="87" x2="204" y2="159" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint4_linear___TwtmIViB" x1="79" y1="169" x2="79" y2="241" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint5_linear___TwtmIViB" x1="89" y1="169" x2="89" y2="241" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint6_linear___TwtmIViB" x1="194" y1="169" x2="194" y2="241" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint7_linear___TwtmIViB" x1="204" y1="169" x2="204" y2="241" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint8_linear___TwtmIViB" x1="79" y1="251" x2="79" y2="323" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint9_linear___TwtmIViB" x1="89" y1="251" x2="89" y2="323" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint10_linear___TwtmIViB" x1="194" y1="251" x2="194" y2="323" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint11_linear___TwtmIViB" x1="204" y1="251" x2="204" y2="323" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint12_linear___TwtmIViB" x1="79" y1="333" x2="79" y2="405" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint13_linear___TwtmIViB" x1="89" y1="333" x2="89" y2="405" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint14_linear___TwtmIViB" x1="194" y1="333" x2="194" y2="405" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint15_linear___TwtmIViB" x1="204" y1="333" x2="204" y2="405" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <clipPath id="clip0___TwtmIViB">
          <rect width="37" height="36" fill="white" transform="translate(41 81)"></rect>
        </clipPath>
        <clipPath id="clip1___TwtmIViB">
          <rect width="36" height="36" fill="white" transform="translate(156 81)"></rect>
        </clipPath>
        <clipPath id="clip2___TwtmIViB">
          <rect width="37" height="36" fill="white" transform="translate(41 163)"></rect>
        </clipPath>
        <clipPath id="clip3___TwtmIViB">
          <rect width="36" height="36" fill="white" transform="translate(156 163)"></rect>
        </clipPath>
        <clipPath id="clip4___TwtmIViB">
          <rect width="37" height="36" fill="white" transform="translate(41 245)"></rect>
        </clipPath>
        <clipPath id="clip5___TwtmIViB">
          <rect width="36" height="36" fill="white" transform="translate(156 245)"></rect>
        </clipPath>
        <clipPath id="clip6___TwtmIViB">
          <rect width="37" height="36" fill="white" transform="translate(41 327)"></rect>
        </clipPath>
        <clipPath id="clip7___TwtmIViB">
          <rect width="36" height="36" fill="white" transform="translate(156 327)"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CompactPreviewSVG;
