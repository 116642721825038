import { Select } from "antd";
import { useEffect, useState } from "react";

function SelectMoveReason({ value, onChange }) {
  const [lstReason, setLstReason] = useState([
    { value: "consumed", label: "Consumed" },
    { value: "damaged", label: "Damaged" },
    { value: "donation", label: "Donation" },
    { value: "end_of_life", label: "End of Life (EOL)" },
    { value: "expired", label: "Expired" },
    { value: "gift", label: "Gift" },
    { value: "inventory_count_adjustment", label: "Inventory Count Adjustment" },
    { value: "invoice_not_received", label: "Invoice Not Received" },
    { value: "item_recall", label: "Item Recall" },
    { value: "other", label: "Other" },
    { value: "out_of_season", label: "Out of Season" },
    { value: "quality_control", label: "Quality Control" },
    { value: "replenish", label: "Replenish" },
    { value: "return_to_supplier", label: "Return to Supplier" },
    { value: "signed_in", label: "Signed In" },
    { value: "signed_out", label: "Signed Out" },
    { value: "sold", label: "Sold" },
  ]);

  useEffect(() => {
    onChange(lstReason[0].value);
  }, []);

  return <Select value={value} showSearch optionFilterProp="label" options={lstReason} onChange={onChange} />;
}

export default SelectMoveReason;
