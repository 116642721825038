var api_url ="http://localhost:3068/api/"
var socket_url ='http://localhost:3068'
// api_url ="https://devapi.360inventory.app/api/"
var server = true;
if(server){
    api_url ="https://devapi.360inventory.app/api/"
    socket_url ='https://devapi.360inventory.app'
}



export {api_url,socket_url};