import { Modal, Divider, Form, Flex, Select, Image, InputNumber, Input, Button, Switch } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { SaveButton } from "components/Buttons";
import ItemService from "services/item";
import { useLoading } from "context/loading";
import { useAppContext } from "context/app";
import { useNotification } from "context/notification";
import * as Picker from "components/pickers";
import { getQuantityItem } from "common/get_quantity_item";
import FolderService from "services/folder";

const itemService = new ItemService();
const folderService = new FolderService();

function AddOrRemoveTagItemModal({ items, folderId, type, visible, onClose }) {
  const [lstItems, setLstItems] = useState([]);
  const { showLoading, dismissLoading } = useLoading();
  const { success, error } = useNotification();
  const [form] = Form.useForm();
  const { eventBus } = useAppContext();

  useEffect(() => {
    if (items) {
      const handleItems = async () => {
        showLoading();

        const itemPromises = items.map(async (item) => {
          if (item.entity_type === "item" && item.has_variants) {
            const folderStockPromises = item.folder_stock.variant_stock.map(async (variantStock) => {
              const result = await itemService.getByID(variantStock.item_id);
              result.entity_type = "item";
              return result;
            });

            return await Promise.all(folderStockPromises);
          }

          return item;
        });

        const result = await Promise.all(itemPromises);
        const lstItems = result.flat();

        setLstItems(lstItems);

        form.setFieldsValue({
          notes: "",
          to_folder_id: folderId,
        });

        dismissLoading();
      };

      handleItems();
    }
  }, [items]);

  const handleSave = async () => {
    showLoading();

    try {
      const items = lstItems.filter((itemFilter) => itemFilter.entity_type === "item").map((item) => item.id);
      const folder = lstItems.filter((itemFilter) => itemFilter.entity_type === "folder").map((item) => item.id);
      const tags = form.getFieldValue("tags");

      if (type === "Add") {
        await itemService.addTags({ item_ids: items, tags });
        await folderService.addTags({ item_ids: folder, tags });
      } else {
        await itemService.removeTags({ item_ids: items, tags });
        await folderService.removeTags({ item_ids: folder, tags });
      }

      eventBus.emit("item_changed", { folder_id: folderId });
      success(`${type} tag has been successfully updated`);
    } catch (err) {
      error(`Failed to ${type} tag. Please try again later.`);
      console.log(err);
    } finally {
      resetValue();
      dismissLoading();
    }
  };

  const resetValue = () => {
    form.resetFields();
    setLstItems([]);
    onClose();
  };

  const renderListItem = () => {
    const itemCounts = lstItems.reduce(
      (counts, item) => {
        if (item.entity_type === "item") {
          counts.items += 1;
        } else if (item.entity_type === "folder") {
          counts.folders += 1;
        }
        return counts;
      },
      { items: 0, folders: 0 }
    );

    const { items, folders } = itemCounts;

    if (items > 0 && folders > 0) {
      return (
        <span style={{ display: "block", fontSize: "18px", fontWeight: 500 }}>
          {folders} Folders & {items} Items
        </span>
      );
    }

    if (items > 0) {
      return <span style={{ display: "block", fontSize: "18px", fontWeight: 500 }}>{items} Items</span>;
    }

    if (folders > 0) {
      return <span style={{ display: "block", fontSize: "18px", fontWeight: 500 }}>{folders} Folders</span>;
    }
  };

  return (
    <Modal
      width={500}
      title={<p style={{ fontSize: "18px", textAlign: "center" }}>{type} Tags</p>}
      open={visible}
      onCancel={resetValue}
      maskClosable={false}
      footer={<SaveButton onClick={handleSave} />}
    >
      <Divider style={{ margin: "10px 0" }} />

      {renderListItem()}

      <Divider style={{ margin: "10px 0" }} />

      <Form form={form} layout="vertical">
        <Form.Item label="Tags" name="tags">
          <Picker.Tags></Picker.Tags>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddOrRemoveTagItemModal;
