import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as Page from "pages";

const PageRoutes = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<>home</>} />
      <Route path="/items" element={<Page.Items />} />
      <Route path="/item/add/:folder_id" element={<Page.AddItem />} />
      <Route path="/item/variants/:id" element={<Page.EditItemVariants />} />
      <Route path="/item-edit/:id" element={<Page.EditItem />} />
      <Route path="/item-detail/:id" element={<Page.ItemDetail />} />
      <Route path="/folder/add/:folder_id" element={<Page.AddFolder />} />
      <Route path="/folder/:id" element={<Page.Items />} />
      <Route path="/folder-edit/:id" element={<Page.EditFolder />} />
      <Route path="/tags" element={<Page.Tags />} />
      <Route path="/setting/custom-fields" element={<Page.CustomField />} />
      <Route path="/" element={<>home</>} />
      <Route path="*" element={<>not found</>} />
    </Routes>
  );
};

export default React.memo(PageRoutes);
