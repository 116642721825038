import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider, Col, Row, Card, Pagination, theme, Image, Dropdown, Checkbox } from "antd";
import * as Icon from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import DefaultImages from "assets/DefaultImage"
import * as Text from "components/Text"
import _ from "lodash"
import { useAppContext } from "context/app";
import { useItemsScreenContext } from "pages/items"
import Tags from "components/tag-list"
const { useToken } = theme;
const Item = ({ data }) => {
    const navigate = useNavigate();
    const { token } = useToken();
    const [thumbnail, setThumbnail] = useState(DefaultImages.NoImageItem);
    const { showExportModal, showCreateLabelModal, showMoveFolderModal } = useAppContext();
    const { selectedItems, selectItem, currentFolder } = useItemsScreenContext();
    const [check, setCheck] = useState(false)

    useEffect(() => {
        // var url = data.photos?[0].url;
        //
        if (data.photos && data.photos[0]) {
            setThumbnail(data.photos[0].url);
        } else {
            setThumbnail(null);
        }


        var i = selectedItems.find(x => x.id == data.id);
        if (i) {
            setCheck(true);
        } else {
            setCheck(false);
        }

    }
        , [data, selectedItems])


    const iconStyle = { fontSize: 18 }


    const options = [
        {
            key: 'move',
            label: (
                <div>
                    Move to Folder
                </div>
            ),
            icon: (<Icon.FolderOutlined style={{ ...iconStyle }} ></Icon.FolderOutlined>)
        },

        {
            key: 'update_quantity',
            label: (
                <div>
                    Update Quantity
                </div>
            ),
            icon: (<Icon.DiffOutlined style={{ ...iconStyle }} ></Icon.DiffOutlined>)
        },

        {
            key: 'create_label',
            label: (
                <div>
                    Create Label
                </div>
            ),
            icon: (<Icon.BarcodeOutlined style={{ ...iconStyle }}></Icon.BarcodeOutlined>)
        },

        {
            key: 'export',
            label: (
                <div>
                    Export
                </div>
            ),
            icon: (<Icon.UploadOutlined style={{ ...iconStyle }}></Icon.UploadOutlined>)
        },

        {
            key: 'transaction',
            label: (
                <div>
                    Transactions
                </div>
            ),
            icon: (<Icon.AccountBookOutlined style={{ ...iconStyle }}></Icon.AccountBookOutlined>)
        },




        {
            type: 'divider', // Must have
        },


        {
            key: 'clone',
            label: (
                <div>
                    Clone
                </div>
            ),
            icon: (<Icon.CopyOutlined style={{ ...iconStyle }}></Icon.CopyOutlined>)
        },
        {
            key: 'edit',
            label: (
                <div>
                    Edit
                </div>
            ),
            icon: (<Icon.EditOutlined style={{ ...iconStyle }}></Icon.EditOutlined>)
        },
        {
            key: 'edit',
            label: (
                <div>
                    Delete
                </div>
            ),
            icon: (<Icon.DeleteOutlined style={{ ...iconStyle }}></Icon.DeleteOutlined>)
        },

    ];

    const menuClick = ({ item, key, keyPath, domEvent }) => {
        console.log({ item, key, keyPath })

        switch (key) {
            case "edit":
                navigate(`/folder-edit/${data.id}`)
                break;
            case "create_label":
                const listItemsLabel = [{ ...data, entity_type: "folder" }]
                showCreateLabelModal(listItemsLabel, currentFolder)
                break;
            case "export":
                const listItemsExport = [{ ...data, entity_type: "folder" }]
                showExportModal(listItemsExport, currentFolder);
                break;
            case "move":
                const listItemsMove = [{ ...data, entity_type: "folder" }]
                showMoveFolderModal(listItemsMove, currentFolder)
                break;
            default:
                break;
        }
    }


    return (
        <div
            style={{
                width: "100%",
                paddingTop: "80%",
                borderRadius: 8,
                backgroundColor: token.colorBgContainer,
                borderColor: token.colorBorder,
                borderStyle: "solid",
                borderWidth: 1,
                position: "relative", overflow: "hidden"
            }}

        >

            <div style={{ position: "absolute", top: 0, height: "100%", width: "100%" }}>
                <div style={{ position: "absolute", top: 0, width: "100%", zIndex: 2, display: "flex", paddingLeft: token.padding }}>
                    <div style={{ flexGrow: 1, display: "flex" }} >

                        <Checkbox checked={check} onChange={e => selectItem({ ...data, entity_type: "folder" }, e.target.checked)}></Checkbox>

                    </div>
                    <div>
                        <Dropdown
                            menu={{
                                items: options,
                                onClick: menuClick,

                            }}

                        >
                            <Button type="link" size="large"> <Icon.MoreOutlined /></Button>

                        </Dropdown>
                    </div>
                </div>

                <div style={{ height: "45%", width: "100%",  overflow: "hidden", justifyContent: "center" }} onClick={e => navigate(`/folder/${data.id}`)}>
                    {thumbnail ?
                        <img src={thumbnail} style={{ width: "100%", height: "100%", objectFit: "cover" }} onClick={e => navigate(`/item-detail/${data.id}`)}></img>

                        :
                        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Icon.FolderOutlined style={{ fontSize: 48, color: token.colorPrimary }} />
                        </div>


                    }
                </div>

                <div style={{ padding: token.padding }}>


                    <div style={{ display: "flex", flexDirection: "row"}}>
                        <div style={{
                            flexGrow: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap",
                            display: "flex",
                            alignItems: "center"


                        }}>
                            <Text.Title>
                                {data.name}
                            </Text.Title>

                        </div>




                    </div>

                    <div style={{ justifyContent: "flex-end", display: "flex" }}>
                        <Tags tag_ids={data.tags}></Tags>
                    </div>
                </div>
            </div>


            {/* //{JSON.stringify(data)} */}
        </div>
    )
}




export default memo(Item);