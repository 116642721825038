import numeral from "numeral";

function format(number, format) {
    return numeral(number).format("0,000.00")
}

function formatFloat(number, format) {
    return numeral(number).format("0,000.00")
}

function formatInt(number, format) {
    return numeral(number).format("0,000")
}



export {format,formatFloat,formatInt}