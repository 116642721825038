function getAlertItem(item, folderId) {
    if (item.quantity_alerts) {
        for (const alert of item.quantity_alerts) {
            if (alert.folder_id === folderId) {
                return alert;
            }
        }
    }

    return null;
}

export { getAlertItem };
