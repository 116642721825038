function ListPreviewSVG() {
  return (
    <svg width="302" height="434" viewBox="0 0 302 434" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d___zxhvW9NV)">
        <rect x="20" y="15" width="262" height="394" rx="6" fill="white"></rect>
        <rect x="20.5" y="15.5" width="261" height="393" rx="5.5" stroke="#E9E9F4"></rect>
      </g>
      <rect x="45.25" y="288.25" width="211.5" height="0.5" stroke="#E9E9F4" stroke-width="0.5" stroke-dasharray="3 3"></rect>
      <rect x="45.25" y="174.25" width="211.5" height="0.5" stroke="#E9E9F4" stroke-width="0.5" stroke-dasharray="3 3"></rect>
      <ellipse cx="46.5231" cy="41.5" rx="1.52311" ry="1.5" fill="#DBDBEC"></ellipse>
      <ellipse cx="52.0006" cy="41.5" rx="1.52311" ry="1.5" fill="#DBDBEC"></ellipse>
      <ellipse cx="57.4772" cy="41.5" rx="1.52311" ry="1.5" fill="#DBDBEC"></ellipse>
      <rect x="221" y="40" width="36" height="4" rx="1" fill="#EDEDF6"></rect>
      <rect x="229" y="48" width="28" height="4" rx="1" fill="#EDEDF6"></rect>
      <rect x="45" y="305" width="123" height="10" rx="1" fill="#A5A9BE"></rect>
      <path d="M153.104 305H168.604C168.881 305 169.104 305.224 169.104 305.5V314.5C169.104 314.776 168.881 315 168.604 315H153.104V305Z" fill="#EDEDF6"></path>
      <rect x="103" y="328" width="26" height="6" rx="1" fill="#A5A9BE"></rect>
      <rect x="133" y="328" width="70" height="6" rx="1" fill="#EDEDF6"></rect>
      <path d="M123 328H128C128.552 328 129 328.448 129 329V333C129 333.552 128.552 334 128 334H123V328Z" fill="#EDEDF6"></path>
      <rect opacity="0.85" x="103" y="341" width="26" height="6" rx="1" fill="#DCDBEC"></rect>
      <rect x="133" y="341" width="70" height="6" rx="1" fill="#EDEDF6"></rect>
      <rect opacity="0.85" x="103" y="353" width="26" height="6" rx="1" fill="#DCDBEC"></rect>
      <rect x="133" y="353" width="70" height="6" rx="1" fill="#EDEDF6"></rect>
      <rect opacity="0.85" x="103" y="366" width="26" height="6" rx="1" fill="#DCDBEC"></rect>
      <rect x="133" y="366" width="70" height="6" rx="1" fill="#EDEDF6"></rect>
      <rect opacity="0.85" x="103" y="378" width="26" height="6" rx="1" fill="#DCDBEC"></rect>
      <rect x="133" y="378" width="38" height="6" rx="1" fill="#EDEDF6"></rect>
      <path
        d="M251.285 329.011V333.923C251.285 334.145 251.105 334.325 250.883 334.325H249.224C249.002 334.325 248.823 334.145 248.823 333.923V332.272C248.823 332.051 248.643 331.871 248.421 331.871H246.762C246.54 331.871 246.36 332.051 246.36 332.272V333.931C246.36 334.153 246.54 334.333 246.762 334.333H248.421C248.643 334.333 248.823 334.513 248.823 334.734V336.385C248.823 336.607 249.002 336.787 249.224 336.787H253.414C253.636 336.787 253.816 336.607 253.816 336.385V334.726C253.816 334.505 253.996 334.325 254.217 334.325H256.599C256.821 334.325 257 334.145 257 333.923V329.011C257 328.789 256.821 328.609 256.599 328.609H251.686C251.465 328.609 251.285 328.789 251.285 329.011Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M239.573 339.283H243.497C243.718 339.283 243.898 339.103 243.898 338.881V337.222C243.898 337 243.718 336.82 243.497 336.82H239.573C239.352 336.82 239.172 337 239.172 337.222V338.881C239.172 339.103 239.352 339.283 239.573 339.283Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M245.959 328.645H242.859C242.637 328.645 242.457 328.824 242.457 329.046V330.705C242.457 330.927 242.637 331.107 242.859 331.107H245.959C246.181 331.107 246.36 330.927 246.36 330.705V329.046C246.36 328.824 246.181 328.645 245.959 328.645Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M248.906 343.807V342.148C248.906 341.926 248.726 341.746 248.504 341.746H246.845C246.623 341.746 246.443 341.926 246.443 342.148V343.807C246.443 344.028 246.623 344.208 246.845 344.208H248.504C248.726 344.208 248.906 344.028 248.906 343.807Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M253.816 341.743V341.533V339.683C253.816 339.461 253.636 339.281 253.414 339.281H249.307C249.085 339.281 248.905 339.461 248.905 339.683V341.342C248.905 341.564 249.085 341.743 249.307 341.743H250.751C250.973 341.743 251.153 341.923 251.153 342.145V343.676V344.206V348.99C251.153 349.211 251.332 349.391 251.554 349.391H256.467C256.688 349.391 256.868 349.211 256.868 348.99V344.077C256.868 343.855 256.688 343.676 256.467 343.676H254.217C253.996 343.676 253.816 343.496 253.816 343.274V341.743H253.816Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M253.815 337.257V338.916C253.815 339.138 253.995 339.318 254.217 339.318H255.876C256.098 339.318 256.278 339.138 256.278 338.916V337.257C256.278 337.035 256.098 336.855 255.876 336.855H254.217C253.995 336.855 253.815 337.035 253.815 337.257Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M237.864 341.743H238.77C238.992 341.743 239.172 341.564 239.172 341.342V339.683C239.172 339.461 238.992 339.281 238.77 339.281H237.111C236.889 339.281 236.709 339.461 236.709 339.683V340.812C236.709 341.034 236.53 341.214 236.308 341.214H235.402C235.18 341.214 235 341.393 235 341.615V343.676V344.333V348.99C235 349.211 235.18 349.391 235.402 349.391H240.314C240.536 349.391 240.716 349.211 240.716 348.99V344.077C240.716 343.856 240.536 343.676 240.314 343.676H237.864C237.642 343.676 237.462 343.496 237.462 343.274V342.145C237.462 341.923 237.642 341.743 237.864 341.743Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M245.64 344.207V342.147C245.64 341.925 245.819 341.745 246.041 341.745C246.263 341.745 246.443 341.565 246.443 341.344V339.719C246.443 339.497 246.623 339.318 246.844 339.318H248.42C248.642 339.318 248.822 339.138 248.822 338.916V337.257C248.822 337.035 248.642 336.855 248.42 336.855H246.761C246.54 336.855 246.36 337.035 246.36 337.257V338.882C246.36 339.103 246.18 339.283 245.958 339.283H244.382C244.16 339.283 243.981 339.463 243.981 339.685V341.344C243.981 341.565 243.801 341.745 243.579 341.745H241.837C241.615 341.745 241.436 341.925 241.436 342.147V343.806C241.436 344.028 241.615 344.207 241.837 344.207H242.776C242.998 344.207 243.178 344.387 243.178 344.609V346.268C243.178 346.49 243.357 346.67 243.579 346.67H245.222C245.444 346.67 245.624 346.849 245.624 347.071V348.73C245.624 348.952 245.803 349.132 246.025 349.132H250.192C250.414 349.132 250.594 348.952 250.594 348.73V347.071C250.594 346.849 250.414 346.67 250.192 346.67H246.041C245.82 346.67 245.64 346.49 245.64 346.268V344.207H245.64Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M246.36 336.42V334.761C246.36 334.539 246.18 334.359 245.958 334.359H244.299C244.077 334.359 243.897 334.539 243.897 334.761V336.42C243.897 336.642 244.077 336.822 244.299 336.822H245.958C246.18 336.822 246.36 336.642 246.36 336.42Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M237.111 336.822H238.77C238.992 336.822 239.172 336.642 239.172 336.421V334.761C239.172 334.54 239.352 334.36 239.573 334.36H240.457H242.776C242.998 334.36 243.178 334.18 243.178 333.958V332.299C243.178 332.078 242.998 331.898 242.776 331.898H241.117C240.895 331.898 240.716 331.718 240.716 331.496V329.046C240.716 328.824 240.536 328.645 240.314 328.645H235.402C235.18 328.645 235 328.824 235 329.046V333.959C235 334.18 235.18 334.36 235.402 334.36H236.308C236.53 334.36 236.709 334.54 236.709 334.762V336.421C236.71 336.642 236.889 336.822 237.111 336.822Z"
        fill="#A5A9BE"
      ></path>
      <g clip-path="url(#clip0___zxhvW9NV)">
        <rect x="45" y="329" width="51" height="55" rx="2" fill="#FCFCFF"></rect>
        <ellipse opacity="0.3" cx="104" cy="388" rx="47" ry="47" transform="rotate(-90 104 388)" fill="url(#paint0_linear___zxhvW9NV)"></ellipse>
        <ellipse opacity="0.85" cx="116" cy="388" rx="47" ry="47" transform="rotate(-90 116 388)" fill="url(#paint1_linear___zxhvW9NV)"></ellipse>
      </g>
      <rect x="45" y="192" width="123" height="10" rx="1" fill="#A5A9BE"></rect>
      <path d="M153.104 192H168.604C168.881 192 169.104 192.224 169.104 192.5V201.5C169.104 201.776 168.881 202 168.604 202H153.104V192Z" fill="#EDEDF6"></path>
      <rect x="103" y="215" width="26" height="6" rx="1" fill="#A5A9BE"></rect>
      <rect x="133" y="215" width="70" height="6" rx="1" fill="#EDEDF6"></rect>
      <path d="M123 215H128C128.552 215 129 215.448 129 216V220C129 220.552 128.552 221 128 221H123V215Z" fill="#EDEDF6"></path>
      <rect opacity="0.85" x="103" y="228" width="26" height="6" rx="1" fill="#DCDBEC"></rect>
      <rect x="133" y="228" width="70" height="6" rx="1" fill="#EDEDF6"></rect>
      <rect opacity="0.85" x="103" y="240" width="26" height="6" rx="1" fill="#DCDBEC"></rect>
      <rect x="133" y="240" width="70" height="6" rx="1" fill="#EDEDF6"></rect>
      <rect opacity="0.85" x="103" y="253" width="26" height="6" rx="1" fill="#DCDBEC"></rect>
      <rect x="133" y="253" width="70" height="6" rx="1" fill="#EDEDF6"></rect>
      <rect opacity="0.85" x="103" y="265" width="26" height="6" rx="1" fill="#DCDBEC"></rect>
      <rect x="133" y="265" width="38" height="6" rx="1" fill="#EDEDF6"></rect>
      <path
        d="M251.285 216.011V220.923C251.285 221.145 251.105 221.325 250.883 221.325H249.224C249.002 221.325 248.823 221.145 248.823 220.923V219.272C248.823 219.051 248.643 218.871 248.421 218.871H246.762C246.54 218.871 246.36 219.051 246.36 219.272V220.931C246.36 221.153 246.54 221.333 246.762 221.333H248.421C248.643 221.333 248.823 221.513 248.823 221.734V223.385C248.823 223.607 249.002 223.787 249.224 223.787H253.414C253.636 223.787 253.816 223.607 253.816 223.385V221.726C253.816 221.505 253.996 221.325 254.217 221.325H256.599C256.821 221.325 257 221.145 257 220.923V216.011C257 215.789 256.821 215.609 256.599 215.609H251.686C251.465 215.609 251.285 215.789 251.285 216.011Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M239.573 226.283H243.497C243.718 226.283 243.898 226.103 243.898 225.881V224.222C243.898 224 243.718 223.82 243.497 223.82H239.573C239.352 223.82 239.172 224 239.172 224.222V225.881C239.172 226.103 239.352 226.283 239.573 226.283Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M245.959 215.645H242.859C242.637 215.645 242.457 215.824 242.457 216.046V217.705C242.457 217.927 242.637 218.107 242.859 218.107H245.959C246.181 218.107 246.36 217.927 246.36 217.705V216.046C246.36 215.824 246.181 215.645 245.959 215.645Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M248.906 230.807V229.148C248.906 228.926 248.726 228.746 248.504 228.746H246.845C246.623 228.746 246.443 228.926 246.443 229.148V230.807C246.443 231.028 246.623 231.208 246.845 231.208H248.504C248.726 231.208 248.906 231.028 248.906 230.807Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M253.816 228.743V228.533V226.683C253.816 226.461 253.636 226.281 253.414 226.281H249.307C249.085 226.281 248.905 226.461 248.905 226.683V228.342C248.905 228.564 249.085 228.743 249.307 228.743H250.751C250.973 228.743 251.153 228.923 251.153 229.145V230.676V231.206V235.99C251.153 236.211 251.332 236.391 251.554 236.391H256.467C256.688 236.391 256.868 236.211 256.868 235.99V231.077C256.868 230.855 256.688 230.676 256.467 230.676H254.217C253.996 230.676 253.816 230.496 253.816 230.274V228.743H253.816Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M253.815 224.257V225.916C253.815 226.138 253.995 226.318 254.217 226.318H255.876C256.098 226.318 256.278 226.138 256.278 225.916V224.257C256.278 224.035 256.098 223.855 255.876 223.855H254.217C253.995 223.855 253.815 224.035 253.815 224.257Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M237.864 228.743H238.77C238.992 228.743 239.172 228.564 239.172 228.342V226.683C239.172 226.461 238.992 226.281 238.77 226.281H237.111C236.889 226.281 236.709 226.461 236.709 226.683V227.812C236.709 228.034 236.53 228.214 236.308 228.214H235.402C235.18 228.214 235 228.393 235 228.615V230.676V231.333V235.99C235 236.211 235.18 236.391 235.402 236.391H240.314C240.536 236.391 240.716 236.211 240.716 235.99V231.077C240.716 230.856 240.536 230.676 240.314 230.676H237.864C237.642 230.676 237.462 230.496 237.462 230.274V229.145C237.462 228.923 237.642 228.743 237.864 228.743Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M245.64 231.207V229.147C245.64 228.925 245.819 228.745 246.041 228.745C246.263 228.745 246.443 228.565 246.443 228.344V226.719C246.443 226.497 246.623 226.318 246.844 226.318H248.42C248.642 226.318 248.822 226.138 248.822 225.916V224.257C248.822 224.035 248.642 223.855 248.42 223.855H246.761C246.54 223.855 246.36 224.035 246.36 224.257V225.882C246.36 226.103 246.18 226.283 245.958 226.283H244.382C244.16 226.283 243.981 226.463 243.981 226.685V228.344C243.981 228.565 243.801 228.745 243.579 228.745H241.837C241.615 228.745 241.436 228.925 241.436 229.147V230.806C241.436 231.028 241.615 231.207 241.837 231.207H242.776C242.998 231.207 243.178 231.387 243.178 231.609V233.268C243.178 233.49 243.357 233.67 243.579 233.67H245.222C245.444 233.67 245.624 233.849 245.624 234.071V235.73C245.624 235.952 245.803 236.132 246.025 236.132H250.192C250.414 236.132 250.594 235.952 250.594 235.73V234.071C250.594 233.849 250.414 233.67 250.192 233.67H246.041C245.82 233.67 245.64 233.49 245.64 233.268V231.207H245.64Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M246.36 223.42V221.761C246.36 221.539 246.18 221.359 245.958 221.359H244.299C244.077 221.359 243.897 221.539 243.897 221.761V223.42C243.897 223.642 244.077 223.822 244.299 223.822H245.958C246.18 223.822 246.36 223.642 246.36 223.42Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M237.111 223.822H238.77C238.992 223.822 239.172 223.642 239.172 223.421V221.761C239.172 221.54 239.352 221.36 239.573 221.36H240.457H242.776C242.998 221.36 243.178 221.18 243.178 220.958V219.299C243.178 219.078 242.998 218.898 242.776 218.898H241.117C240.895 218.898 240.716 218.718 240.716 218.496V216.046C240.716 215.824 240.536 215.645 240.314 215.645H235.402C235.18 215.645 235 215.824 235 216.046V220.959C235 221.18 235.18 221.36 235.402 221.36H236.308C236.53 221.36 236.709 221.54 236.709 221.762V223.421C236.71 223.642 236.889 223.822 237.111 223.822Z"
        fill="#A5A9BE"
      ></path>
      <g clip-path="url(#clip1___zxhvW9NV)">
        <rect x="45" y="216" width="51" height="55" rx="2" fill="#FCFCFF"></rect>
        <ellipse opacity="0.3" cx="104" cy="275" rx="47" ry="47" transform="rotate(-90 104 275)" fill="url(#paint2_linear___zxhvW9NV)"></ellipse>
        <ellipse opacity="0.85" cx="116" cy="275" rx="47" ry="47" transform="rotate(-90 116 275)" fill="url(#paint3_linear___zxhvW9NV)"></ellipse>
      </g>
      <rect x="45" y="78" width="123" height="10" rx="1" fill="#A5A9BE"></rect>
      <path d="M153.104 78H168.604C168.881 78 169.104 78.2239 169.104 78.5V87.5C169.104 87.7761 168.881 88 168.604 88H153.104V78Z" fill="#EDEDF6"></path>
      <rect x="103" y="101" width="26" height="6" rx="1" fill="#A5A9BE"></rect>
      <rect x="133" y="101" width="70" height="6" rx="1" fill="#EDEDF6"></rect>
      <path d="M123 101H128C128.552 101 129 101.448 129 102V106C129 106.552 128.552 107 128 107H123V101Z" fill="#EDEDF6"></path>
      <rect opacity="0.85" x="103" y="114" width="26" height="6" rx="1" fill="#DCDBEC"></rect>
      <rect x="133" y="114" width="70" height="6" rx="1" fill="#EDEDF6"></rect>
      <rect opacity="0.85" x="103" y="126" width="26" height="6" rx="1" fill="#DCDBEC"></rect>
      <rect x="133" y="126" width="70" height="6" rx="1" fill="#EDEDF6"></rect>
      <rect opacity="0.85" x="103" y="139" width="26" height="6" rx="1" fill="#DCDBEC"></rect>
      <rect x="133" y="139" width="70" height="6" rx="1" fill="#EDEDF6"></rect>
      <rect opacity="0.85" x="103" y="151" width="26" height="6" rx="1" fill="#DCDBEC"></rect>
      <rect x="133" y="151" width="38" height="6" rx="1" fill="#EDEDF6"></rect>
      <path
        d="M251.285 102.011V106.923C251.285 107.145 251.105 107.325 250.883 107.325H249.224C249.002 107.325 248.823 107.145 248.823 106.923V105.272C248.823 105.051 248.643 104.871 248.421 104.871H246.762C246.54 104.871 246.36 105.051 246.36 105.272V106.931C246.36 107.153 246.54 107.333 246.762 107.333H248.421C248.643 107.333 248.823 107.513 248.823 107.734V109.385C248.823 109.607 249.002 109.787 249.224 109.787H253.414C253.636 109.787 253.816 109.607 253.816 109.385V107.726C253.816 107.505 253.996 107.325 254.217 107.325H256.599C256.821 107.325 257 107.145 257 106.923V102.011C257 101.789 256.821 101.609 256.599 101.609H251.686C251.465 101.609 251.285 101.789 251.285 102.011Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M239.573 112.283H243.497C243.718 112.283 243.898 112.103 243.898 111.881V110.222C243.898 110 243.718 109.82 243.497 109.82H239.573C239.352 109.82 239.172 110 239.172 110.222V111.881C239.172 112.103 239.352 112.283 239.573 112.283Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M245.959 101.645H242.859C242.637 101.645 242.457 101.824 242.457 102.046V103.705C242.457 103.927 242.637 104.107 242.859 104.107H245.959C246.181 104.107 246.36 103.927 246.36 103.705V102.046C246.36 101.824 246.181 101.645 245.959 101.645Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M248.906 116.807V115.148C248.906 114.926 248.726 114.746 248.504 114.746H246.845C246.623 114.746 246.443 114.926 246.443 115.148V116.807C246.443 117.028 246.623 117.208 246.845 117.208H248.504C248.726 117.208 248.906 117.028 248.906 116.807Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M253.816 114.743V114.533V112.683C253.816 112.461 253.636 112.281 253.414 112.281H249.307C249.085 112.281 248.905 112.461 248.905 112.683V114.342C248.905 114.564 249.085 114.743 249.307 114.743H250.751C250.973 114.743 251.153 114.923 251.153 115.145V116.676V117.206V121.99C251.153 122.211 251.332 122.391 251.554 122.391H256.467C256.688 122.391 256.868 122.211 256.868 121.99V117.077C256.868 116.855 256.688 116.676 256.467 116.676H254.217C253.996 116.676 253.816 116.496 253.816 116.274V114.743H253.816Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M253.815 110.257V111.916C253.815 112.138 253.995 112.318 254.217 112.318H255.876C256.098 112.318 256.278 112.138 256.278 111.916V110.257C256.278 110.035 256.098 109.855 255.876 109.855H254.217C253.995 109.855 253.815 110.035 253.815 110.257Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M237.864 114.743H238.77C238.992 114.743 239.172 114.564 239.172 114.342V112.683C239.172 112.461 238.992 112.281 238.77 112.281H237.111C236.889 112.281 236.709 112.461 236.709 112.683V113.812C236.709 114.034 236.53 114.214 236.308 114.214H235.402C235.18 114.214 235 114.393 235 114.615V116.676V117.333V121.99C235 122.211 235.18 122.391 235.402 122.391H240.314C240.536 122.391 240.716 122.211 240.716 121.99V117.077C240.716 116.856 240.536 116.676 240.314 116.676H237.864C237.642 116.676 237.462 116.496 237.462 116.274V115.145C237.462 114.923 237.642 114.743 237.864 114.743Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M245.64 117.207V115.147C245.64 114.925 245.819 114.745 246.041 114.745C246.263 114.745 246.443 114.565 246.443 114.344V112.719C246.443 112.497 246.623 112.318 246.844 112.318H248.42C248.642 112.318 248.822 112.138 248.822 111.916V110.257C248.822 110.035 248.642 109.855 248.42 109.855H246.761C246.54 109.855 246.36 110.035 246.36 110.257V111.882C246.36 112.103 246.18 112.283 245.958 112.283H244.382C244.16 112.283 243.981 112.463 243.981 112.685V114.344C243.981 114.565 243.801 114.745 243.579 114.745H241.837C241.615 114.745 241.436 114.925 241.436 115.147V116.806C241.436 117.028 241.615 117.207 241.837 117.207H242.776C242.998 117.207 243.178 117.387 243.178 117.609V119.268C243.178 119.49 243.357 119.67 243.579 119.67H245.222C245.444 119.67 245.624 119.849 245.624 120.071V121.73C245.624 121.952 245.803 122.132 246.025 122.132H250.192C250.414 122.132 250.594 121.952 250.594 121.73V120.071C250.594 119.849 250.414 119.67 250.192 119.67H246.041C245.82 119.67 245.64 119.49 245.64 119.268V117.207H245.64Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M246.36 109.42V107.761C246.36 107.539 246.18 107.359 245.958 107.359H244.299C244.077 107.359 243.897 107.539 243.897 107.761V109.42C243.897 109.642 244.077 109.822 244.299 109.822H245.958C246.18 109.822 246.36 109.642 246.36 109.42Z"
        fill="#A5A9BE"
      ></path>
      <path
        d="M237.111 109.822H238.77C238.992 109.822 239.172 109.642 239.172 109.421V107.761C239.172 107.54 239.352 107.36 239.573 107.36H240.457H242.776C242.998 107.36 243.178 107.18 243.178 106.958V105.299C243.178 105.078 242.998 104.898 242.776 104.898H241.117C240.895 104.898 240.716 104.718 240.716 104.496V102.046C240.716 101.824 240.536 101.645 240.314 101.645H235.402C235.18 101.645 235 101.824 235 102.046V106.959C235 107.18 235.18 107.36 235.402 107.36H236.308C236.53 107.36 236.709 107.54 236.709 107.762V109.421C236.71 109.642 236.889 109.822 237.111 109.822Z"
        fill="#A5A9BE"
      ></path>
      <g clip-path="url(#clip2___zxhvW9NV)">
        <rect x="45" y="102" width="51" height="55" rx="2" fill="#FCFCFF"></rect>
        <ellipse opacity="0.3" cx="104" cy="161" rx="47" ry="47" transform="rotate(-90 104 161)" fill="url(#paint4_linear___zxhvW9NV)"></ellipse>
        <ellipse opacity="0.85" cx="116" cy="161" rx="47" ry="47" transform="rotate(-90 116 161)" fill="url(#paint5_linear___zxhvW9NV)"></ellipse>
      </g>
      <defs>
        <filter id="filter0_d___zxhvW9NV" x="0" y="0" width="302" height="434" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="10"></feGaussianBlur>
          <feColorMatrix type="matrix" values="0 0 0 0 0.235294 0 0 0 0 0.25098 0 0 0 0 0.345098 0 0 0 0.03 0"></feColorMatrix>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
        </filter>
        <linearGradient id="paint0_linear___zxhvW9NV" x1="104" y1="341" x2="104" y2="435" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint1_linear___zxhvW9NV" x1="116" y1="341" x2="116" y2="435" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint2_linear___zxhvW9NV" x1="104" y1="228" x2="104" y2="322" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint3_linear___zxhvW9NV" x1="116" y1="228" x2="116" y2="322" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint4_linear___zxhvW9NV" x1="104" y1="114" x2="104" y2="208" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint5_linear___zxhvW9NV" x1="116" y1="114" x2="116" y2="208" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDEDF6"></stop>
          <stop offset="1" stop-color="#EDEDF6" stop-opacity="0"></stop>
        </linearGradient>
        <clipPath id="clip0___zxhvW9NV">
          <rect width="51" height="55" fill="white" transform="translate(45 329)"></rect>
        </clipPath>
        <clipPath id="clip1___zxhvW9NV">
          <rect width="51" height="55" fill="white" transform="translate(45 216)"></rect>
        </clipPath>
        <clipPath id="clip2___zxhvW9NV">
          <rect width="51" height="55" fill="white" transform="translate(45 102)"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ListPreviewSVG;
