import BaseService from "services/base";
import _ from "lodash";

class ItemService extends BaseService {

    list = async (query = {}, sort = {}, option = { group_item: false, paging: { page: 1, page_zize: 10 } }) => {
        var data = await this._api.post("item/list", { query, sort, option });
        return data;
    };



    add = async (item) => {
        var data = await this._api.post("item/add", item);
        return data;
    };


    updateGeneralInfomation = async (item) => {
        var data = await this._api.post("item/update/general", item);
        return data;
    };

    updateVariants = async (item) => {
        var data = await this._api.post("item/update/variant", item);
        return data;
    };

    updateQuantity = async ({ item_id, folder_id, notes, quantity, reason_id }) => {
        var data = await this._api.post("item/update/quantity", { item_id, folder_id, notes, reason_id, quantity });
        return data;
    };


    moveQuantity = async ({ item_id, folder_id, to_folder_id, quantity, notes, reason_id }) => {
        var data = await this._api.post("item/move/quantity", { item_id, folder_id, notes, reason_id, to_folder_id, quantity });
        return data;
    };



    getByID = async (id) => {
        var data = await this._api.get("item/get", { params: { id } });
        return data;
    }


    /**
     * 
     * @param {Array[{ item_id, folder_id, notes, quantity, reason_id }]} itemList 
     * @returns 
     */
    bulkUpdateQuantity = async (itemList) => {
        var data = await this._api.post("item/bulkupdate/quantity", itemList);
        return data;
    };


    /**
     * 
     * @param {Array[ { item_id, folder_id, notes, reason_id, to_folder_id, quantity }]} itemList 
     * @returns 
     */
    bulkMoveQuantity = async (itemList) => {
        var data = await this._api.post("item/bulkmove/quantity", itemList);
        return data;
    };


    /**
     * 
     * @param {
     * item_ids:  array of item id
     * tags:  array of tag id
     * } data 
     * @returns 
     */

    addTags = async ({ item_ids, tags }) => {
        var data = await this._api.post("item/addtags", { item_ids, tags });
        return data;
    }



    /**
     * 
     * @param {
    * item_ids:  array of item id
    * tags:  array of tag id
    * } data 
    * @returns 
    */
    removeTags = async ({ item_ids, tags }) => {
        var data = await this._api.post("item/removetags", { item_ids, tags });
        return data;
    }







}

export default ItemService;
