import React, { useState } from "react";
import { DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined, GatewayOutlined, TagsOutlined, AreaChartOutlined, SettingOutlined } from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import PageRoutes from "./PageRoutes";
import { BrowserRouter, useNavigate } from "react-router-dom";

import _ from "lodash";
import { useAppLayoutContext } from "./index";

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("Dashboard", "dashboard", <PieChartOutlined />),
  getItem("Items", "items", <GatewayOutlined />),
  getItem("Tags", "tags", <TagsOutlined />),
  getItem("Reports", "reports", <AreaChartOutlined />, [
    getItem("Activity History", "activity-history"),
    getItem("Inventory Sumary", "inventory-sumary"),
    getItem("Transactions", "transactions"),
    getItem("Move Sumary", "move-sumary"),
    getItem("Report 2", "8"),
  ]),
  getItem("Settings", "setting", <SettingOutlined />, [
    getItem("User Profile", "user-profile"),
    getItem("Preferences", "preferences"),
    getItem("User Access Control", "user-access-control"),
    getItem("Custom Fields", "custom-fields"),
    getItem("Create Labels", "create-labels"),
  ]),
];
const LeftMenu = () => {
  const { menuCollapsed, setMenuCollapsed } = useAppLayoutContext();

  const navigate = useNavigate();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const onClick = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    var path = _.reverse(keyPath).join("/");
    console.log({ path, item, key, keyPath, selectedKeys, domEvent });
    navigate(`/${path}`);
  };

  return (
    <Sider collapsible collapsed={menuCollapsed} onCollapse={(value) => setMenuCollapsed(value)} >
      <div style={{ position: "fixed", width: menuCollapsed ? 80 : 200, transition: `width 0.2s` }}>
        <div className="demo-logo-vertical" />
        <div style={{ color: "white", textAlign: "center" }}>
          <h1>360</h1>
        </div>
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline" items={items} onClick={onClick} />
      </div>
    </Sider>
  );
};

export default LeftMenu;
