import { Form, Select, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import { MultiImagePicker } from "components/pickers";
import { useCreateLabel } from "context/label";
import { useEffect, useState } from "react";
import LabelService from "services/label";
import TagService from "services/tag";

function OptionsAndSettingsLabel({ items, form, setSelectedLabelSize }) {
  const [lstLabelType, setLstLabelType] = useState([]);
  const [lstPaperSize, setLstPaperSize] = useState([]);
  const [lstLabelSize, setLstLabelSize] = useState([]);
  const lstDetail = [
    { value: "id", label: "ID" },
    { value: "name", label: "Name" },
    { value: "photos", label: "Photos" },
    { value: "quantity", label: "Quantity" },
    { value: "min_level", label: "Min Level" },
    { value: "price", label: "Price" },
    { value: "total_value", label: "Total Value" },
    { value: "tags", label: "Tags" },
    { value: "custom_field", label: "Custom Field" },
    { value: "notes", label: "Notes" },
  ];
  const [hasFolder, setHasFolder] = useState(false);

  const has_detail = Form.useWatch("has_detail", { form });
  const has_photo = Form.useWatch("has_photo", { form });
  const has_logo_or_icon = Form.useWatch("has_logo_or_icon", { form });
  const has_note = Form.useWatch("has_note", { form });
  const label_type = Form.useWatch("label_type", { form });
  const paper_size = Form.useWatch("paper_size", { form });

  useEffect(() => {
    // Kiểm tra trong items có item nào là folder, nếu có hiển thị options Include Sub-Folders and Items
    const hasFolder = items.some((item) => item.entity_type === "folder");
    setHasFolder(hasFolder);

    fetchLstLabelType();
    fetchLstPaperSize();
  }, [items]);

  const fetchLstLabelType = async () => {
    const labelService = new LabelService();
    const result = await labelService.getLabelTypes();
    setLstLabelType(result);

    form.setFieldsValue({
      label_type: result.length > 0 ? result[0]?.value : undefined,
    });
  };

  const fetchLstPaperSize = async () => {
    const labelService = new LabelService();
    const result = await labelService.getPaperSizes();
    setLstPaperSize(result);

    form.setFieldsValue({
      paper_size: result[0].value || undefined,
      label_size: result[0].size_labels[0].value || undefined,
    });

    setLstLabelSize(result[0].size_labels || []);
    setSelectedLabelSize(result[0].size_labels[0] || {});
  };

  return (
    <>
      <p>LABEL OPTIONS</p>
      <Form.Item name="label_type" label="Label type">
        <Select showSearch optionFilterProp="label" options={lstLabelType} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item name="paper_size" label="Paper size">
        <Select
          showSearch
          optionFilterProp="label"
          options={lstPaperSize}
          style={{ width: "100%" }}
          onChange={(value, data) => {
            setLstLabelSize(data.size_labels);
            form.setFieldsValue({
              label_size: data.size_labels[0],
            });
            setSelectedLabelSize(data.size_labels[0]);
          }}
        />
      </Form.Item>

      <Form.Item name="label_size" label="Label size">
        <Select
          showSearch
          optionFilterProp="label"
          options={lstLabelSize}
          style={{ width: "100%" }}
          onChange={(value) => {
            lstPaperSize.forEach((paperSize) => {
              if (paperSize.value === paper_size) {
                const selected = paperSize.size_labels.find((size) => size.value === value);
                setSelectedLabelSize(selected);
              }
            });
          }}
        />
      </Form.Item>

      <p>LABEL SETTINGS</p>
      <Form.Item hidden={!hasFolder} label="Include Sub-Folders and Items" name="has_subFolders_items" valuePropName="checked" layout="horizontal" initialValue={true}>
        <Switch />
      </Form.Item>

      <Form.Item name="has_border" label="Includes additional item borders" valuePropName="checked" layout="horizontal">
        <Switch />
      </Form.Item>

      <Form.Item name="has_detail" label="Include additional item details" valuePropName="checked" layout="horizontal">
        <Switch />
      </Form.Item>

      <Form.Item name="detail" label="" style={{ marginTop: "-24px" }} hidden={!has_detail} initialValue={lstDetail[0]}>
        <Select
          showSearch
          optionFilterProp="label"
          options={lstDetail}
          style={{ width: "100%" }}
          onChange={(value, data) => {
            form.setFieldsValue({ detail: data });
          }}
        />
      </Form.Item>

      <Form.Item name="has_photo" label="Include photo" valuePropName="checked" layout="horizontal">
        <Switch />
      </Form.Item>
      <p hidden={!has_photo} style={{ marginTop: "-24px" }}>
        The first photo of the item will be used on the label
      </p>

      <Form.Item name="has_logo_or_icon" label="Include logo or icon" valuePropName="checked" layout="horizontal">
        <Switch />
      </Form.Item>

      <Form.Item label="" name="logo_or_icon" hidden={!has_logo_or_icon} style={{ marginTop: "-24px" }}>
        <MultiImagePicker maxPhotos={1} acceptedFormats={["image/jpeg", "image/png"]} maxFileSize={5242880} />
      </Form.Item>

      <Form.Item name="has_note" label="Add a note to label" valuePropName="checked" layout="horizontal">
        <Switch />
      </Form.Item>

      <Form.Item label="" name="note" hidden={!has_note} style={{ marginTop: "-24px" }}>
        <TextArea />
      </Form.Item>
    </>
  );
}
export default OptionsAndSettingsLabel;
