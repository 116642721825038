export const SHORT_TEXT = "short_text";
export const LONG_TEXT = "long_text";
export const ROUND_NUMBER = "round_number";
export const DECIMAL_NUMBER = "decimal_number";
export const CHECK_BOX = "check_box";
export const DROPDOWN = "dropdown";
export const PHONE_NUMBER = "phone_number";
export const EMAIL = "email";
export const LINK = "link";
export const DATE = "date";
export const SCANNER = "scanner";
export const FILE = "file";
