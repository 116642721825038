import * as  React from "react";
import { Button, Flex, Space, Divider, Col, Row, Card, Pagination, theme, Image, Dropdown, Avatar, Tooltip } from "antd";
import { useAppContext } from "context/app";
import tag from "components/pickers/tag";
const { useToken } = theme;


const Tags = React.memo(({ tag_ids = [] }) => {
    const { token } = useToken();
    const [tags, setTags] = React.useState([])
    const { tagList } = useAppContext();
    React.useEffect(() => {
        console.log("chnagessss", tag_ids)
    //     var a = [];
    //     for (let index = 0; index < tag_ids.length; index++) {
    //         const tag = tagList.find(x => x.id == tag_ids[index]);
    //         if (tag) {
    //             a.push({...tag});
    //         }



    //     }
    //     console.log("", a)
    //    setTags(a)

    }, [tagList, tag_ids]);


    React.useEffect(() => {
        console.log("chnage", tag_ids)
        var a = [];
        for (let index = 0; index < tag_ids.length; index++) {
            const tag = tagList.find(x => x.id == tag_ids[index]);
            if (tag) {
                a.push({...tag});
            }



        }
        console.log("tagList", a)
       setTags(a)

    }, [tagList]);


    return (

 
            <Avatar.Group
                max={{
                    count: 4,
                    style: { color: '#f56a00', backgroundColor: '#fde3cf' },
                }}
                size={18}
                shape="square"
            >
                {tags.map(tag => {
                    return (
                        <Tooltip title={tag.title} placement="top">
                            <Avatar size={18} style={{ backgroundColor: tag.color}}  >{ tag.title[0].toUpperCase()}</Avatar>
                        </Tooltip>
                    )
                })}


            </Avatar.Group>
     

    )
})


export default Tags;