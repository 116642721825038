import { useForm } from "antd/es/form/Form";
import { createContext, useState, useContext } from "react";

const CreateLabelContext = createContext();

function CreateLabelProvider({ children }) {
  const [dataLabel, setDataLabel] = useState({});

  return <CreateLabelContext.Provider value={{ dataLabel, setDataLabel }}>{children}</CreateLabelContext.Provider>;
}

const useCreateLabel = () => {
  const context = useContext(CreateLabelContext);

  return context;
};

export { useCreateLabel };
export default CreateLabelProvider;
