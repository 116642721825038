import { Button, Table, Popconfirm } from "antd";
import AddCustomFieldModal from "components/CustomField/AddOrEditCustomFieldModal";
import { useEffect, useState } from "react";
import CustomField from "services/custom_field";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

function CustomFieldPage() {
  const [showModal, setShowModal] = useState(false);
  const [listFieldCustom, setListFieldCustom] = useState([]);
  const [editField, setEditField] = useState(null);

  useEffect(() => {
    fetchCustomField();
  }, []);

  const fetchCustomField = async () => {
    const customField = new CustomField();
    const result = await customField.get();

    setListFieldCustom(result);
  };

  const onChange = () => {
    fetchCustomField();
    setShowModal(false);
    setEditField(null);
  };

  const confirm = async (data) => {
    const customField = new CustomField();
    const result = await customField.remove(data.id);

    if (result) {
      fetchCustomField();
    }
  };

  const cancel = (e) => {
    console.log(e);
  };

  const handleEdit = (field) => {
    setEditField(field);
    setShowModal(true);
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
    },
    {
      title: "FIELD TYPE",
      dataIndex: "field_type",
    },
    // {
    //   title: "DEFAULT VALUE",
    //   dataIndex: "default_value",
    // },
    // {
    //   title: "PLACEHOLDER",
    //   dataIndex: "placeholder",
    // },
    {
      title: "APPLICABLE TO",
      dataIndex: "applicable",
      render: (text, record, index) => {
        const applicableTo = [];

        if (text.item) {
          applicableTo.push("item");
        }
        if (text.folder) {
          applicableTo.push("folder");
        }

        return <div>{applicableTo.join(" & ")}</div>;
      },
    },
    {
      title: "ACTIONS",
      dataIndex: "action",
      render: (text, record, index) => (
        <>
          <div>
            <EditOutlined style={{ cursor: "pointer", marginRight: "16px" }} onClick={() => handleEdit(record)} />
            <Popconfirm title="Delete the custom field" description="Are you sure to delete this custom field?" onConfirm={() => confirm(record)} onCancel={cancel} okText="Yes" cancelText="No">
              <DeleteOutlined style={{ cursor: "pointer" }} />
            </Popconfirm>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <AddCustomFieldModal
        visible={showModal}
        onClose={() => {
          setShowModal(false);
          setEditField(null);
        }}
        onChange={onChange}
        value={editField}
      />
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #ccc", paddingBottom: "10px", marginBottom: "30px" }}>
        <span style={{ fontSize: "30px", fontWeight: 500 }}>Custom Fields</span>
        <Button type="primary" onClick={() => setShowModal(true)}>
          ADD CUSTOM FIELD
        </Button>
      </div>
      <Table dataSource={listFieldCustom} columns={columns} />
    </>
  );
}

export default CustomFieldPage;
