import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider } from "antd";

import { BrowserRouter, useNavigate, useParams } from "react-router-dom";
import ItemService from "services/item";
import ItemEditor from "components/item/Editor"
import { useLoading } from "context/loading";
import { useAppContext } from "context/app";
const itemService = new ItemService();

const ItemEditPage = () => {
    const navigate = useNavigate();
    const [item, setItem] = useState({});
    const [isLoading, setIsloading] = useState(true);
    let { id } = useParams();
    const { eventBus } = useAppContext();
    const loading = useLoading();

    useEffect(() => {
        loadItem();
        const off = eventBus.on("item_changed", payload => {
            console.log("item item_changed");
            loadItem();
        })
        return off;

    }, [id]);


    const loadItem = async () => {
        setIsloading(true);
        loading.showLoading();
        var data = await itemService.getByID(id);
        setItem(data);
        loading.dismissLoading();
        setIsloading(false);

    }





    return (
        <div>

            <ItemEditor data={item}></ItemEditor>
        </div>
    )
}




export default memo(ItemEditPage);