import { memo, useEffect, useState } from "react";
import ListTag from "./ListTag";
import AddOrEditTagModal from "components/Tag/AddOrEditTagModal";
import { Button } from "antd";
import TagService from "services/tag";

function TagsPage() {
  const [listTag, setListTag] = useState([]);
  const [selectedTag, setSelectedTag] = useState({});
  const [showModalTag, setShowModalTag] = useState(false);

  useEffect(() => {
    fetchListTag();
  }, []);

  const fetchListTag = async () => {
    const tagService = new TagService();
    const result = await tagService.list();

    setListTag(result);
    setSelectedTag(result[0]);
  };

  const onAdd = (result) => {
    setListTag((prevItem) => [...prevItem, result]);
    setShowModalTag(false);
    setSelectedTag(result);
  };

  return (
    <>
      <AddOrEditTagModal visible={showModalTag} onClose={() => setShowModalTag(false)} onAdd={onAdd} />

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <ListTag listTag={listTag} setListTag={setListTag} selectedTag={selectedTag} setSelectedTag={setSelectedTag} />
        </div>
        <div style={{ flex: 4 }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <h2>New Tag</h2>
            <Button type="primary" onClick={() => setShowModalTag(true)}>
              ADD TAG
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(TagsPage);
