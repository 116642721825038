import React, { useState, memo, useEffect } from 'react';
import { TreeSelect } from 'antd';
import FolderService from "services/folder";
import * as Icon from '@ant-design/icons';
import { useAppContext } from "context/app";
import { useItemsScreenContext } from 'pages/items';
const folderService = new FolderService();

const FolderPicker = ({value, onChange, style, lstItemDisable = null }) => {
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [treeData, setTreeData] = useState([]);
    
    var allFolder =   useAppContext().folderList;

    useEffect(() => {
        loadTreeData();
    }, [allFolder, lstItemDisable])

    useEffect(() => {
        if (value) {
            var tree = toTreeData(allFolder);
            const parentKeys = findParentKeys(tree, value);
            setExpandedKeys([...parentKeys, value]);
        }
    }, [value]);
    
    const loadTreeData = async () => {
     
        var tree = toTreeData(allFolder);
        setTreeData(tree)
    }

    const toTreeData = (lst) => {
        var root = lst.find(x => x.is_root);
        if(!root) return [];
        var arrarLoop = [];
        var tree = { value: root.id, title: 'All Folder', children: [], id: root.id, key: root.id }
        arrarLoop.push(tree);
        while (arrarLoop.length > 0) {
            var node = arrarLoop.pop();
            var children = lst
                .filter(x => x.parent_id === node.id)
                .map(x => {
                    // Xử lý disable các folder đã chọn trong trường hợp move folder
                    if(lstItemDisable) {
                        const lstFolderDisable = lstItemDisable
                        .map((item) => {
                            if (item.entity_type === "folder") {
                            return item.id;
                            }
                            return null;
                        })
                        .filter((id) => id !== null);

                        if(lstFolderDisable.includes(x.id)) {
                            return { value: x.id, title: x.name, id: x.id, children: [], key: x.id, disabled: true }
                        }
                    }

                    return { value: x.id, title: x.name, id: x.id, children: [], key: x.id }
                });
            node.children = children;
            node.children.forEach(childNode => {
                arrarLoop.push(childNode);
            });
        }
        return [tree];
    }

    const findParentKeys = (nodes, targetKey, parents = []) => {
        for (let node of nodes) {
            if (node.key === targetKey) {
                return parents;
            }
            if (node.children) {
                const foundParents = findParentKeys(node.children, targetKey, [...parents, node.key]);
                if (foundParents.length > 0) {
                    return foundParents;
                }
            }
        }
        return [];
    };


    return (
        <TreeSelect
            style={{
                width: 400,
                ...style,
            }}
            value={value}
            dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
            }}
            suffixIcon={<Icon.DownOutlined size={64} />}
            treeData={treeData}
            placeholder="Please select"
            treeLine={true}
            // treeDefaultExpandAll
            onChange={onChange}
            treeExpandedKeys={expandedKeys} 
        />
    );
};
export default memo(FolderPicker);