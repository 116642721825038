import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider, Col, Row, Card, Pagination, Spin } from "antd";
import Item from "components/item-list/Gird/Item";
import _ from "lodash"
import { useItemsScreenContext } from "pages/items"

const Gird = ({ items = [], loading }) => {
    const { paging, setPaging } = useItemsScreenContext();
    const onPageChange = (page, pageSize) => {
        // console.log("onpge  chnage",page, pageSize);
        setPaging({ ...paging, page: page, page_size: pageSize })
    }
    if (loading) {
        return (
            <div style={{ padding: 18, justifyContent: "center" }}>


                <Spin size="large" />

            </div>

        )
    }

    return (
        <div>


            <Row gutter={[32, 32]}>
                {
                    items.map(item => {
                        return (
                            <Col span={6} lg={8} md={8} sm={12} xl={4}>
                                <Item data={item}></Item>
                            </Col>
                        )
                    })
                }
            </Row>
            <Divider></Divider>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <Pagination current={paging.page} total={paging.total_row} pageSize={paging.page_size} onChange={onPageChange} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} showSizeChanger={true} />
            </div>

        </div>
    )
}




export default memo(Gird);