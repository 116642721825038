function AlbumSVG() {
  return (
    <svg width="45" height="58" viewBox="0 0 45 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="43" height="56" rx="2" stroke="currentColor" stroke-width="2"></rect>
      <rect x="6" y="6" width="33" height="20" rx="1" fill="currentColor"></rect>
      <rect x="6" y="36.8555" width="33" height="2.5" rx="0.5" fill="currentColor"></rect>
      <rect x="6" y="32" width="33" height="2.5" rx="0.5" fill="currentColor"></rect>
      <rect x="6" y="41.7109" width="33" height="2.5" rx="0.5" fill="currentColor"></rect>
      <rect x="6" y="46.5625" width="16.1489" height="2.5" rx="0.5" fill="currentColor"></rect>
    </svg>
  );
}

export default AlbumSVG;
