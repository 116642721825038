import { useEffect, memo } from "react";
import { Button, Flex, Space, Divider } from "antd";

import ItemEditor from "components/folder/Add"
const AddFolderPage = () => {
    return (
        <div>
       
            <ItemEditor data={{}}></ItemEditor>
        
        </div>
    )
}




export default memo(AddFolderPage);