import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";

function Search({ placeholder, onChange }) {
  const [textSearch, setTextSearch] = useState("");
  const inputRef = useRef();

  return (
    <div style={{ display: "flex", borderBottom: "1px solid #ccc", paddingBottom: "20px" }}>
      <SearchOutlined style={{ fontSize: "16px" }} />
      <div style={{ display: "flex", position: "relative", flex: 1 }}>
        <input
          ref={inputRef}
          placeholder={placeholder}
          value={textSearch}
          onChange={(e) => {
            setTextSearch(e.target.value);
            onChange(e.target.value);
          }}
          style={{
            minWidth: "90%",
            outline: "none",
            border: "none",
            padding: "10px",
            fontSize: "16px",
            "::placeholder": {
              color: "#ccc",
            },
          }}
        />

        {textSearch && (
          <CloseOutlined
            style={{ cursor: "pointer", position: "absolute", right: "20px", top: "50%", transform: "translateY(-50%)" }}
            onClick={() => {
              setTextSearch("");
              onChange("");
              inputRef.current.focus();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Search;
