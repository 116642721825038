import { useState, useEffect, useCallback } from "react";
import Search from "components/Search/Search";
import "./index.css";
import TagService from "services/tag";
import Tag from "components/Tag/Tag";
import { Empty } from "antd";
import _ from "lodash";

function ListTag({ listTag, setListTag, selectedTag, setSelectedTag }) {
  const handleClickTag = (item) => {
    setSelectedTag(item);
  };

  const handleSearch = useCallback(
    _.debounce(async (textSearch) => {
      const tagService = new TagService();
      const result = await tagService.list({ info: textSearch });
      setListTag(result);
      if (result.length > 0) {
        setSelectedTag(result[0]);
      }
    }, 500),
    []
  );

  const onChange = (data, mode) => {
    let listTagNew;
    if (mode === "edit") {
      listTagNew = listTag.map((item) => (item.id === data.id ? data : item));
    } else {
      listTagNew = listTag.filter((item) => item.id !== data.id);
    }
    setListTag(listTagNew);
    setSelectedTag(mode === "edit" ? data : listTagNew[0]);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", marginRight: "20px" }}>
      <div>
        <Search placeholder={"Search tags"} onChange={handleSearch} />

        <div className="list-tag">
          {listTag.length > 0 ? (
            listTag.map((item) => (
              <div key={item.id} onClick={() => handleClickTag(item)}>
                <Tag item={item} selectedTag={selectedTag} onChange={onChange} />
              </div>
            ))
          ) : (
            <Empty style={{ margin: "auto" }} />
          )}
        </div>
      </div>
    </div>
  );
}

export default ListTag;
