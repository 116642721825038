import React from 'react';
import { Divider, Flex, Tag, Button, Modal, Input } from 'antd';
import * as Icon from '@ant-design/icons';
const QrCodePicker = ({ value, onChange }) => {


  return (

    <>
      <Flex gap="4px 0" wrap>
        <Input value={value} onChange={onChange} suffix={<Button type='link'> <Icon.QrcodeOutlined></Icon.QrcodeOutlined>Qr/Bar code</Button>}></Input>

      </Flex>


    </>
  );
}
export default QrCodePicker;