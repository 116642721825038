function ListSVG() {
  return (
    <svg width="45" height="58" viewBox="0 0 45 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="43" height="56" rx="2" stroke="currentColor" stroke-width="2"></rect>
      <rect x="6" y="6" width="13.3196" height="13.3196" rx="1" fill="currentColor"></rect>
      <rect x="22.3213" y="6" width="16.6799" height="2.72334" rx="0.5" fill="currentColor"></rect>
      <rect x="22.3213" y="11.2969" width="16.6799" height="2.72334" rx="0.5" fill="currentColor"></rect>
      <rect x="22.3213" y="16.5938" width="16.6799" height="2.72334" rx="0.5" fill="currentColor"></rect>
      <rect x="6" y="22.3398" width="13.3196" height="13.3196" rx="1" fill="currentColor"></rect>
      <rect x="22.3213" y="22.3398" width="16.6799" height="2.72334" rx="0.5" fill="currentColor"></rect>
      <rect x="22.3213" y="27.6367" width="16.6799" height="2.72334" rx="0.5" fill="currentColor"></rect>
      <rect x="22.3213" y="32.9375" width="16.6799" height="2.72334" rx="0.5" fill="currentColor"></rect>
      <rect x="6" y="38.6797" width="13.3196" height="13.3196" rx="1" fill="currentColor"></rect>
      <rect x="22.3213" y="38.6797" width="16.6799" height="2.72334" rx="0.5" fill="currentColor"></rect>
      <rect x="22.3213" y="43.9766" width="16.6799" height="2.72334" rx="0.5" fill="currentColor"></rect>
      <rect x="22.3213" y="49.2734" width="16.6799" height="2.72334" rx="0.5" fill="currentColor"></rect>
    </svg>
  );
}

export default ListSVG;
