import BaseService from "services/base";
import _ from "lodash";
const fakeDataSet = [
  { id: "size", title: "Size", options:["s","m","l"]  },
  { id: "color", title: "Color", options:["blue","black","red"]  },

];
class AttributeService extends BaseService {
  get = async () => {
    var data = await this._api.get("attribute/list");
    return data;
  };

  add = async ({ title, options }) => {
    var data = await this._api.post("attribute/add", { title, options });
    return data;
  };

  remove = async (id) => {
    var all = await this.getAll();
    all = _.remove(all, (x) => x.id == id);
    localStorage.setItem("fake.attributes", JSON.stringify(all));
    return true;
  };
}

export default AttributeService;
