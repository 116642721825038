import BaseService from "services/base";
import _ from "lodash";

class FileService extends BaseService {
  /**
   * hàm upload,
   * @param {FormData} formdata
   * @returns {id, url}
   */
  upload = async (formdata) => {
    var file = this._api.post("image/upload", formdata);
    return file;
  };
}

export default FileService;
