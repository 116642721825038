function getStockItem(item, folderId) {
    for (const stock of item.stock) {
        if (stock.folder_id === folderId) {
            return stock;
        }
    }

    return null;
}

export { getStockItem };
