import { Flex, Form, Input, InputNumber, Select, Switch } from "antd";
import { useWatch } from "antd/es/form/Form";
import { useCreateLabel } from "context/label";
import { useEffect, useState } from "react";

function OptionsAndInformation({ form }) {
  const [lstLabelQuantity, setLstLabelQuantity] = useState([
    { value: "1_per_item", label: "1 per item" },
    { value: "custom", label: "Custom" },
    { value: "as_per_current_item_quantity", label: "As per current item quantity" },
  ]);

  const has_copy_to_email = useWatch("has_copy_to_email", { form });
  const label_quantity = useWatch("label_quantity", { form });

  return (
    <>
      <p>PRINTING OPTIONS</p>
      <Flex gap={20}>
        <Form.Item name="label_quantity" label="Label quantity" style={{ flex: "1" }} initialValue={lstLabelQuantity[0].value}>
          <Select showSearch optionFilterProp="label" options={lstLabelQuantity} />
        </Form.Item>

        <Form.Item hidden={label_quantity !== "custom"} name="amount" label="Amount" style={{ flex: "1" }}>
          <InputNumber min={1} />
        </Form.Item>
      </Flex>

      <Form.Item name="has_start_position" label="Choose label print start position" valuePropName="checked" layout="horizontal">
        <Switch />
      </Form.Item>

      <Form.Item name="has_printing_instructions" label="Include printing instructions" valuePropName="checked" layout="horizontal">
        <Switch />
      </Form.Item>

      <Form.Item name="has_copy_to_email" label="Send copy to email" valuePropName="checked" layout="horizontal">
        <Switch />
      </Form.Item>

      <Form.Item hidden={!has_copy_to_email} name="email" label="Email" style={{ marginTop: "-24px" }}>
        <Input placeholder="example@gmail.com" />
      </Form.Item>

      <p>LABEL INFORMATION</p>
      <Flex vertical>
        <span>
          Labels per sheet: <strong>2</strong>
        </span>
        <span>
          Compatible with: <strong>Avery 8126, 5126</strong>
        </span>
        <a>Purchase Blank Labels </a>
      </Flex>

      <Flex vertical style={{ marginTop: "10px" }}>
        <span>
          Printer type: <strong>laser / inkjet</strong>
        </span>
        <span>Printer recommentdation:</span>
        <a>Purchase Recommended Printers </a>
      </Flex>
    </>
  );
}

export default OptionsAndInformation;
