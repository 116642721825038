import { createContext, useState, useContext } from "react";
import { Button, Spin } from 'antd';
const LoadingContext = createContext();


function LoadingProvider({ children }) {
    const [loading, setLoading] = useState(false)

    const [tip, setTip] = useState("Loading")
    const showLoading = (title = "Loading...") => {
        setTip(title);
        setLoading(true);
    }

    const dismissLoading = () => {
        setLoading(false);
    }



    return (
        <LoadingContext.Provider value={{ showLoading, dismissLoading }}>
            {children}
            {loading &&

                <Spin tip={tip} fullscreen  >
                </Spin>
            }
        </LoadingContext.Provider>
    );
}


const useLoading = () => {
    const context = useContext(LoadingContext);
    return context;
}
export { useLoading }
export default LoadingProvider;
