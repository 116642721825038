import React from 'react';
import { Button } from 'antd';
import { ConfigProvider } from 'antd';
import Mainlayout from "components/app-layout"
const App = () => (
  <ConfigProvider theme={{ token: {colorPrimary:"#0284c7", fontFamily:"Inter var,ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji"} }}>
    <Mainlayout></Mainlayout>
  </ConfigProvider>

);

export default App;