import * as exportConstants from "./constants";
import AlbumPreviewSVG from "./svgs/AlbumPreviewSVG";
import CompactPreviewSVG from "./svgs/ConpactPreviewSVG";
import ListPreviewSVG from "./svgs/ListPreviewSVG";
import SpreadsheetPreviewSVG from "./svgs/SpreadsheetPreviewSVG";

function PreviewExport({ selectedExportType, selectedLayoutType }) {
  const showPreviewExport = () => {
    const exportPreviewTypeMap = {
      [exportConstants.SPREADSHEET]: <SpreadsheetPreviewSVG />,
      [exportConstants.INTEGRATIONS]: "",
      [exportConstants.PAGE]: {
        [exportConstants.PAGE_ALBUM]: <AlbumPreviewSVG />,
        [exportConstants.PAGE_LIST]: <ListPreviewSVG />,
        [exportConstants.PAGE_COMPACT]: <CompactPreviewSVG />,
      },
    };

    const exportPreviewComponent = exportPreviewTypeMap[selectedExportType.type];

    if (selectedExportType.type === exportConstants.PAGE) {
      return exportPreviewComponent[selectedLayoutType.type];
    }

    return exportPreviewComponent;
  };

  return showPreviewExport();
}

export default PreviewExport;
