import { Modal, Divider, Form, Flex, Select, Image, InputNumber, Input, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { SaveButton } from "components/Buttons";
import ItemService from "services/item";
import { useLoading } from "context/loading";
import InputPlusMinusNumber from "components/Input/InputPlusMinusNumber";
import { useNotification } from "context/notification";
import { getQuantityItem } from "common/get_quantity_item";
import { useAppContext } from "context/app";

const itemService = new ItemService();

function UpdateMultiQuantityModal({ items, folderId, visible, onClose }) {
  const [lstItems, setLstItems] = useState([]);
  const { showLoading, dismissLoading } = useLoading();
  const { success, error } = useNotification();
  const { eventBus } = useAppContext();
  const [lstReason, setLstReason] = useState([
    { value: "consumed", label: "Consumed" },
    { value: "damaged", label: "Damaged" },
    { value: "restocked", label: "Restocked" },
    { value: "returned", label: "Returned" },
    { value: "sold", label: "Sold" },
    { value: "stocktake", label: "Stocktake" },
    { value: "stolen", label: "Stolen" },
  ]);
  const [disableSave, setDisableSave] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    if (items) {
      const handleItems = async () => {
        showLoading();

        const itemPromises = items.map(async (item) => {
          if (item.has_variants) {
            const folderStockPromises = item.folder_stock.variant_stock.map(async (variantStock) => {
              const result = await itemService.getByID(variantStock.item_id);
              return result;
            });

            return await Promise.all(folderStockPromises);
          }

          return item;
        });

        const result = await Promise.all(itemPromises);
        const lstItems = result.flat();

        setLstItems(lstItems);

        lstItems.forEach((item) => {
          const quantity = getQuantityItem(item, folderId);
          form.setFieldsValue({
            [`quantity_${item.id}`]: 0,
            [`new_quantity_${item.id}`]: quantity,
          });
        });

        form.setFieldsValue({
          reason: lstReason[0].value,
          notes: "",
        });

        dismissLoading();
      };

      handleItems();
    }
  }, [items]);

  const handleChangeQuantity = async (value, item, type) => {
    if (type !== "multi_quantity") {
      form.setFieldsValue({
        multi_new_quantity: undefined,
        multi_quantity: undefined,
      });
    }
    await checkErrors();

    if (value === undefined || value === null) return;

    const updatedNewQuantity = getQuantityItem(item, folderId) + value;
    form.setFieldsValue({ [`new_quantity_${item.id}`]: updatedNewQuantity });

    if (updatedNewQuantity < 0) {
      form.setFields([
        {
          name: `new_quantity_${item.id}`,
          errors: ["New Quantity can't be below 0"],
        },
      ]);
      setDisableSave(true);
    } else {
      await checkErrors();
    }
  };

  const handleChangeNewQuantity = async (value, item, type) => {
    if (type !== "multi_new_quantity") {
      form.setFieldsValue({
        multi_new_quantity: undefined,
        multi_quantity: undefined,
      });
    }
    await checkErrors();

    if (value === undefined || value === null) return;

    const updatedQuantity = value - getQuantityItem(item, folderId);
    form.setFieldsValue({ [`quantity_${item.id}`]: updatedQuantity });
  };

  const handleChangeMultiQuantity = async (value) => {
    form.setFieldsValue({
      multi_new_quantity: undefined,
    });

    for (const item of lstItems) {
      form.setFieldsValue({ [`quantity_${item.id}`]: value });
      await handleChangeQuantity(value, item, "multi_quantity");
    }
  };

  const handleChangeMultiNewQuantity = async (value) => {
    form.setFieldsValue({
      multi_quantity: undefined,
    });

    for (const item of lstItems) {
      form.setFieldsValue({ [`new_quantity_${item.id}`]: value });
      await handleChangeNewQuantity(value, item, "multi_new_quantity");
    }
  };

  const checkErrors = async () => {
    try {
      await form.validateFields();
      setDisableSave(false);
    } catch (error) {
      setDisableSave(true);
    }
  };

  const handleSave = async () => {
    showLoading();

    const data = lstItems.map((item) => ({
      item_id: item.id,
      folder_id: folderId,
      quantity: form.getFieldValue(`quantity_${item.id}`),
      notes: form.getFieldValue("notes"),
      reason_id: form.getFieldValue("reason"),
    }));

    try {
      const result = await itemService.bulkUpdateQuantity(data);
      if (result) {
        eventBus.emit("item_changed", { folder_id: folderId });
        success(`Update quantity has been successfully updated`);
      } else {
        error("Failed to update quantity. Please try again later.");
      }
    } catch (err) {
      console.log(err);
    } finally {
      resetValue();
      dismissLoading();
    }
  };

  const resetValue = () => {
    form.resetFields();
    setLstItems([]);
    onClose();
  };

  return (
    <Modal
      width={1000}
      title={<p style={{ fontSize: "18px", textAlign: "center" }}>Bulk Update Quantity</p>}
      open={visible}
      onCancel={resetValue}
      maskClosable={false}
      footer={<SaveButton disabled={disableSave} onClick={handleSave} />}
    >
      <Form form={form} layout="vertical">
        <Row gutter={30} style={{ padding: "10px 0" }}>
          <Col span={8}>ITEMS ({lstItems?.length})</Col>
          <Col span={4}>OLD QUANTITY</Col>
          <Col span={8}>QUANTITY</Col>
          <Col span={4}>NEW QUANTITY</Col>
        </Row>

        <Row gutter={30}>
          <Col span={8}>Bulk Update</Col>
          <Col span={4}></Col>
          <Col span={8}>
            <Form.Item name="multi_quantity">
              <InputPlusMinusNumber value={form.getFieldValue("multi_quantity")} onChange={handleChangeMultiQuantity} />
            </Form.Item>
          </Col>
          <Col span={4} style={{ paddingRight: "20px" }}>
            <Form.Item name="multi_new_quantity" rules={[{ type: "number", min: 0, message: "New Quantity can't be below 0" }]}>
              <InputNumber style={{ width: "100%" }} onChange={handleChangeMultiNewQuantity} />
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ marginTop: "0", marginBottom: "24px" }} />

        <div style={{ maxHeight: "300px", overflowY: "scroll", overflowX: "hidden", marginRight: "-18px" }}>
          {lstItems?.map((item) => (
            <div key={item.id}>
              <Row gutter={30}>
                <Col span={8}>
                  <Flex gap={10} align="center">
                    <Image
                      style={{ width: "30px", height: "30px", objectFit: "contain", borderRadius: "8px" }}
                      src={item.photos ? item.photos[0].url : "https://storage.googleapis.com/cubebio/default/nodata_2.svg"}
                    />
                    <Flex vertical>
                      <span
                        style={{
                          display: "block",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.name}
                      </span>
                      <span
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {item.variant_title}
                      </span>
                    </Flex>
                  </Flex>
                </Col>
                <Col span={4}>
                  <span style={{ display: "block", paddingTop: "5px" }}>{getQuantityItem(item, folderId)}</span>
                </Col>
                <Col span={8}>
                  <Form.Item name={`quantity_${item.id}`} rules={[{ required: true, message: "Quantity can't be blank" }]}>
                    <InputPlusMinusNumber value={form.getFieldValue(`quantity_${item.id}`)} onChange={(value) => handleChangeQuantity(value, item)} />
                  </Form.Item>
                </Col>
                <Col span={4} style={{ paddingRight: "20px" }}>
                  <Form.Item
                    name={`new_quantity_${item.id}`}
                    rules={[
                      { required: true, message: "New Quantity can't be blank" },
                      { type: "number", min: 0, message: "New Quantity can't be below 0" },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} onChange={(value) => handleChangeNewQuantity(value, item)} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ))}
        </div>

        <Divider style={{ marginBottom: "12px" }} />

        <Row gutter={30} style={{ padding: "10px 0" }}>
          <Col span={10}>
            <Form.Item name="reason" label="Reason (optional)">
              <Select showSearch optionFilterProp="label" options={lstReason} />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item name="notes" label="Transaction Note (optional)">
              <Input.TextArea style={{ height: "30px" }} />
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ marginTop: "-10px", marginBottom: "24px" }} />
      </Form>
    </Modal>
  );
}

export default UpdateMultiQuantityModal;
