function SpreadsheetPreviewSVG() {
  return (
    <svg width="400" height="280" viewBox="0 0 400 280" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0___VHfUKmVO)">
        <g filter="url(#filter0_d___VHfUKmVO)">
          <rect width="400" height="280" rx="16" fill="white"></rect>
          <rect x="0.466667" y="0.466667" width="399.067" height="279.067" rx="15.5333" stroke="#E9E9F4" stroke-width="0.933333"></rect>
        </g>
        <rect opacity="0.2" y="30" width="365.833" height="25" fill="#EFEFF8"></rect>
        <rect opacity="0.2" y="55" width="365.833" height="25" fill="white"></rect>
        <rect opacity="0.2" y="80" width="365.833" height="25" fill="#EFEFF8"></rect>
        <rect opacity="0.2" y="105" width="365.833" height="25" fill="white"></rect>
        <rect opacity="0.2" y="130" width="365.833" height="25" fill="#EFEFF8"></rect>
        <rect opacity="0.2" y="155" width="365.833" height="25" fill="white"></rect>
        <rect opacity="0.2" y="205" width="365.833" height="25" fill="white"></rect>
        <rect opacity="0.2" y="255" width="365.833" height="25" fill="white"></rect>
        <rect opacity="0.2" y="180" width="365.833" height="25" fill="#EFEFF8"></rect>
        <rect opacity="0.2" y="230" width="365.833" height="25" rx="0.466667" fill="#EFEFF8"></rect>
        <path d="M1 15.9333C1 7.68586 7.68588 1 15.9333 1H97V279H15.9333C7.68588 279 1 272.314 1 264.067V15.9333Z" fill="white"></path>
        <rect x="16" y="12" width="56" height="6" rx="1" fill="#C6C5D6"></rect>
        <path d="M60 12H71.5333C71.7911 12 72 12.2089 72 12.4667V17.5333C72 17.7911 71.7911 18 71.5333 18H60V12Z" fill="#EDEDF6"></path>
        <rect opacity="0.85" x="16" y="40" width="64" height="5" rx="1" fill="#A5A9BE"></rect>
        <rect opacity="0.85" x="16" y="65" width="64" height="5" rx="1" fill="#A5A9BE"></rect>
        <rect opacity="0.85" x="16" y="90" width="64" height="5" rx="1" fill="#A5A9BE"></rect>
        <rect opacity="0.85" x="16" y="115" width="64" height="5" rx="1" fill="#A5A9BE"></rect>
        <rect opacity="0.85" x="16" y="140" width="64" height="5" rx="1" fill="#A5A9BE"></rect>
        <rect opacity="0.85" x="16" y="165" width="64" height="5" rx="1" fill="#A5A9BE"></rect>
        <rect opacity="0.85" x="16" y="190" width="64" height="5" rx="1" fill="#A5A9BE"></rect>
        <rect opacity="0.85" x="16" y="215" width="64" height="5" rx="1" fill="#A5A9BE"></rect>
        <rect opacity="0.85" x="16" y="240" width="64" height="5" rx="1" fill="#A5A9BE"></rect>
        <rect opacity="0.85" x="16" y="265" width="64" height="5" rx="1" fill="#A5A9BE"></rect>
        <rect x="97" y="1" width="76" height="278" fill="#FCFCFF"></rect>
        <rect x="113" y="40" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="113" y="65" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="113" y="90" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="113" y="115" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="114" y="140" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="113" y="165" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="113" y="190" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="113" y="215" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="113" y="240" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="113" y="265" width="21" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="113" y="11" width="28" height="6" rx="1" fill="#C6C5D6"></rect>
        <path d="M135 11H140.533C140.791 11 141 11.2089 141 11.4667V16.5333C141 16.7911 140.791 17 140.533 17H135V11Z" fill="#EDEDF6"></path>
        <rect x="249" y="1" width="76" height="278" fill="#FCFCFF"></rect>
        <rect x="265" y="40" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="265" y="65" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="265" y="90" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="265" y="115" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="266" y="140" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="265" y="165" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="265" y="190" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="265" y="215" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="265" y="240" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="265" y="265" width="21" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="265" y="11" width="28" height="6" rx="1" fill="#C6C5D6"></rect>
        <path d="M287 11H292.533C292.791 11 293 11.2089 293 11.4667V16.5333C293 16.7911 292.791 17 292.533 17H287V11Z" fill="#EDEDF6"></path>
        <rect x="173" y="1" width="76" height="278" fill="white"></rect>
        <rect x="189" y="40" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="189" y="65" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="189" y="90" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="189" y="115" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="190" y="140" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="189" y="165" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="189" y="190" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="189" y="215" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="189" y="240" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="189" y="265" width="21" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="189" y="11" width="28" height="6" rx="1" fill="#C6C5D6"></rect>
        <path d="M211 11H216.533C216.791 11 217 11.2089 217 11.4667V16.5333C217 16.7911 216.791 17 216.533 17H211V11Z" fill="#EDEDF6"></path>
        <path d="M325 1H384C392.284 1 399 7.71573 399 16V264C399 272.284 392.284 279 384 279H325V1Z" fill="white"></path>
        <rect x="341" y="40" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="341" y="65" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="341" y="90" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="341" y="115" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="342" y="140" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="341" y="165" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="341" y="190" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="341" y="215" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="341" y="240" width="42" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="341" y="265" width="21" height="5" rx="1" fill="#EDEDF7"></rect>
        <rect x="341" y="11" width="28" height="6" rx="1" fill="#C6C5D6"></rect>
        <path d="M363 11H368.533C368.791 11 369 11.2089 369 11.4667V16.5333C369 16.7911 368.791 17 368.533 17H363V11Z" fill="#EDEDF6"></path>
        <rect y="30" width="400" height="1" fill="#E9E9F4"></rect>
        <rect y="79" width="400" height="1" fill="#F3F3FD"></rect>
        <rect y="55" width="400" height="1" fill="#F3F3FD"></rect>
        <rect y="104" width="400" height="1" fill="#F3F3FD"></rect>
        <rect y="129" width="400" height="1" fill="#F3F3FD"></rect>
        <rect y="154" width="400" height="1" fill="#F3F3FD"></rect>
        <rect y="179" width="400" height="1" fill="#F3F3FD"></rect>
        <rect y="204" width="400" height="1" fill="#F3F3FD"></rect>
        <rect y="229" width="400" height="1" fill="#F3F3FD"></rect>
        <rect y="254" width="400" height="1" fill="#F3F3FD"></rect>
      </g>
      <defs>
        <filter id="filter0_d___VHfUKmVO" x="-18.6667" y="-14" width="437.333" height="317.333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="4.66667"></feOffset>
          <feGaussianBlur stdDeviation="9.33333"></feGaussianBlur>
          <feColorMatrix type="matrix" values="0 0 0 0 0.235294 0 0 0 0 0.25098 0 0 0 0 0.345098 0 0 0 0.03 0"></feColorMatrix>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
        </filter>
        <clipPath id="clip0___VHfUKmVO">
          <rect width="400" height="280" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SpreadsheetPreviewSVG;
