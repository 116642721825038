import { useEffect, memo } from "react";
import { Button, Flex, Space, Divider } from "antd";
import * as Number from "common/number_format"
const Statistic = ({data}) => {
    return (
        <div>
            <Space size="large">
                <div>Folders: <span style={{fontWeight:500}}>{data.folders}</span></div>
                <div>Items: <span style={{fontWeight:500}}>{Number.formatInt( data.rows)}</span></div>
                <div>Total Quantity: <span style={{fontWeight:500}}>{Number.formatInt( data.total_quantity)}</span></div>
                <div>Total Value: <span style={{fontWeight:500}}>{Number.formatFloat(data.total_amount)}</span></div>
            </Space>


        </div>
    )
}




export default memo(Statistic);