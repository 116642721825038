import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider, Col, Row, Card, Pagination, Spin } from "antd";
import Item from "./Item";
import _ from "lodash"
import CreateLabelsModal from "components/Label/CreateLabelsModal";

const Gird = ({ data = [], loading }) => {
    if (loading) {
        return (
            <div style={{ padding: 18, justifyContent: "center" }}>


                <Spin size="large" />

            </div>

        )
    }

    return (

        <Row gutter={[32, 32]}>
            {
                data.map(item => {
                    return (
                        <Col span={6} lg={8} md={8} sm={12} xl={4}>
                            <Item data={item}></Item>
                        </Col>
                    )
                })
            }
        </Row>


    )
}




export default memo(Gird);