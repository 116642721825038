import { Button, Dropdown, Flex, Input, Space, QRCode } from "antd";
import { QrcodeOutlined, DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
//import { QrReader } from "react-qr-reader";

import "./QrAndBarCode.css";
const  QrReader =<div></div>;
const options = [
  {
    key: "create",
    label: <div>Create</div>,
    icon: <QrcodeOutlined></QrcodeOutlined>,
  },
  {
    key: "scan",
    label: <div>Scan</div>,
    icon: <QrcodeOutlined></QrcodeOutlined>,
  },
];

function QrAndBarCode({ value, onChange }) {
  const [showCreate, setShowCreate] = useState(false);
  const [showScanner, setShowScanner] = useState(false);

  useEffect(() => {
    if (value) {
      setShowCreate(true);
      onChange(value);
    }
  }, [value]);

  function doDownload(url, fileName) {
    const a = document.createElement("a");
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const downloadQRCode = () => {
    const canvas = document.getElementById("myqrcode")?.querySelector("canvas");
    if (canvas) {
      const url = canvas.toDataURL();
      doDownload(url, "QRCode.png");
    }
  };

  const menuClick = ({ item, key, keyPath, domEvent }) => {
    console.log({ item, key, keyPath });

    switch (key) {
      case "create":
        setShowCreate(true);
        break;
      case "scan":
        setShowScanner(true);
        break;
      default:
        break;
    }
  };

  const handleResult = (result, error) => {
    if (result) {
      console.log({ result, error });

      onChange(result.text);
      setShowScanner(false);
      setShowCreate(true);
    }

    if (error) {
      // console.error(error);
    }
  };

  return (
    <>
      {showCreate ? (
        <Space direction="vertical" align="center">
          <div id="myqrcode">
            <QRCode value={value || ""} />
          </div>
          <Space>
            <Input placeholder="-" maxLength={60} value={value} onChange={(e) => onChange(e.target.value)} />
            <Button icon={<DownloadOutlined />} onClick={downloadQRCode}></Button>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                onChange("");
                setShowCreate(false);
              }}
            ></Button>
          </Space>
        </Space>
      ) : showScanner ? (
        <div className="custom-qr-code">
          <Space direction="vertical" align="center">
            <QrReader onResult={handleResult} constraints={{ facingMode: "environment" }} />
            <Button icon={<DeleteOutlined />} onClick={() => setShowScanner(false)}>
              Cancel
            </Button>
          </Space>
        </div>
      ) : (
        <Dropdown
          menu={{
            items: options,
            onClick: menuClick,
          }}
        >
          <Button icon={<QrcodeOutlined />}>Qr/Bar code</Button>
        </Dropdown>
      )}
    </>
  );
}

export default QrAndBarCode;
