import React, { useEffect, useRef, useState, useMemo } from "react";
import { LeftOutlined, RightOutlined, DownloadOutlined, PrinterOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();

function PdfViewerModal({ pdfUrl, visible, onClose }) {
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const containerRef = useRef(null);

  const options = useMemo(
    () => ({
      cMapUrl: "cmaps/",
      cMapPacked: true,
    }),
    []
  );

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
  }, [pdfUrl]);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const pageHeight = container.clientHeight;
      const scrollPosition = container.scrollTop;
      const currentPage = Math.floor(scrollPosition / pageHeight) + 1;
      setPageNumber(currentPage);
    }
  };

  const onDocLoad = ({ numPages }) => {
    setTotalPages(numPages);
  };

  const changePage = (param) => {
    if (param === "prev" && pageNumber > 1) {
      setPageNumber((prev) => prev - 1);
      containerRef.current?.scrollTo({
        top: (pageNumber - 2) * containerRef.current.clientHeight,
        behavior: "smooth",
      });
    } else if (param === "next" && pageNumber < totalPages) {
      setPageNumber((prev) => prev + 1);
      containerRef.current?.scrollTo({
        top: pageNumber * containerRef.current.clientHeight,
        behavior: "smooth",
      });
    }
  };

  const handlePrint = () => {
    fetch(pdfUrl).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const iframe = document.createElement("iframe");
        iframe.src = fileURL;
        iframe.className = "iframeClass";
        iframe.style.display = "none";
        iframe.onload = () => {
          iframe.contentWindow.print();
        };
        document.body.appendChild(iframe);
      });
    });
  };

  const handleDownload = () => {
    fetch(pdfUrl).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = pdfUrl.split("/").pop();
        alink.click();
      });
    });
  };

  const removeAllIframe = () => {
    const lstIframe = document.querySelectorAll(`.iframeClass`);
    lstIframe.forEach((iframe) => {
      document.body.removeChild(iframe);
    });
  };

  return (
    <Modal
      width={800}
      open={visible}
      onCancel={() => {
        removeAllIframe();
        setTotalPages(0);
        setPageNumber(1);
        onClose();
      }}
      maskClosable={false}
      footer={null}
      style={{ top: "5px", height: "calc(100vh - 5px)" }}
    >
      <div style={{ backgroundColor: "#f1f5f9", padding: "10px 0", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <div style={{ display: "flex", justifyContent: "center", width: "100%", gap: "40px", marginBottom: "10px" }}>
          <div style={{ display: "flex", gap: "5px" }}>
            <LeftOutlined style={{ cursor: "pointer" }} onClick={() => changePage("prev")} />
            <div style={{ padding: "0 10px" }}>{pageNumber}</div>
            of
            <div style={{ padding: "0 10px" }}>{totalPages}</div>
            <RightOutlined style={{ cursor: "pointer" }} onClick={() => changePage("next")} />
          </div>

          <PrinterOutlined style={{ cursor: "pointer", fontSize: "18px" }} onClick={handlePrint} />
          <DownloadOutlined style={{ cursor: "pointer", fontSize: "18px" }} onClick={handleDownload} />
        </div>

        <div ref={containerRef} style={{ overflowY: "scroll", height: "852px", padding: "0 60px" }}>
          <Document file={pdfUrl} onLoadSuccess={onDocLoad} options={options}>
            {Array.from({ length: totalPages }, (_, index) => (
              <div key={index} style={{ marginBottom: "10px" }}>
                <Page pageNumber={index + 1} />
              </div>
            ))}
          </Document>
        </div>
      </div>
    </Modal>
  );
}

export default PdfViewerModal;
