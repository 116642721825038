import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider, Col, Row, Card, Pagination, theme, Image, Dropdown, Checkbox, Statistic } from "antd";

import * as Icon from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import DefaultImages from "assets/DefaultImage"
import * as Text from "components/Text"
import _ from "lodash"
import { useAppContext } from "context/app";
import { useItemsScreenContext } from "pages/items"
import * as Number from "common/number_format"
import Tags from "components/tag-list"

const { useToken } = theme;
const Item = ({ data }) => {
    console.log("item gen")
    const navigate = useNavigate();
    const { token } = useToken();
    const [thumbnail, setThumbnail] = useState(DefaultImages.NoImageItem);
    const { showExportModal, showCreateLabelModal, showUpdateSingleQuantityModal, showUpdateMultiQuantityModal, showMoveSingleQuantityModal, showAlertModal } = useAppContext();
    const { selectedItems, selectItem, currentFolder, currentFolderPath } = useItemsScreenContext();
    const [check, setCheck] = useState(false)
    useEffect(() => {
        // var url = data.photos?[0].url;
        //
        if (data.photos && data.photos[0]) {
            setThumbnail(data.photos[0].url);
        } else {
            setThumbnail(null);
        }

        var i = selectedItems.find(x => x.id == data.id);
        if (i) {
            setCheck(true);
        } else {
            setCheck(false);
        }

    }
        , [data, selectedItems])


    const iconStyle = { fontSize: 18 }
    const options = [
        {
            key: 'alert',
            label: (
                <div>
                    Alert
                </div>
            ),
            icon: (<Icon.BellOutlined  style={{ ...iconStyle }} ></Icon.BellOutlined>)
        },

        {
            key: 'move',
            label: (
                <div>
                    Move to Folder
                </div>
            ),
            icon: (<Icon.FolderOutlined style={{ ...iconStyle }} ></Icon.FolderOutlined>)
        },

        {
            key: 'update_quantity',
            label: (
                <div>
                    Update Quantity
                </div>
            ),
            icon: (<Icon.DiffOutlined style={{ ...iconStyle }} ></Icon.DiffOutlined>)
        },

        {
            key: 'create_label',
            label: (
                <div>
                    Create Label
                </div>
            ),
            icon: (<Icon.BarcodeOutlined style={{ ...iconStyle }}></Icon.BarcodeOutlined>)
        },

        {
            key: 'export',
            label: (
                <div>
                    Export
                </div>
            ),
            icon: (<Icon.UploadOutlined style={{ ...iconStyle }}></Icon.UploadOutlined>)
        },

        {
            key: 'transaction',
            label: (
                <div>
                    Transactions
                </div>
            ),
            icon: (<Icon.AccountBookOutlined style={{ ...iconStyle }}></Icon.AccountBookOutlined>)
        },




        {
            type: 'divider', // Must have
        },


        {
            key: 'clone',
            label: (
                <div>
                    Clone
                </div>
            ),
            icon: (<Icon.CopyOutlined style={{ ...iconStyle }}></Icon.CopyOutlined>)
        },
        {
            key: 'edit',
            label: (
                <div>
                    Edit
                </div>
            ),
            icon: (<Icon.EditOutlined style={{ ...iconStyle }}></Icon.EditOutlined>)
        },
        {
            key: 'edit',
            label: (
                <div>
                    Delete
                </div>
            ),
            icon: (<Icon.DeleteOutlined style={{ ...iconStyle }}></Icon.DeleteOutlined>)
        },

    ];

    const menuClick = ({ item, key, keyPath, domEvent }) => {
        console.log({ item, key, keyPath })

        switch (key) {
            case "edit":
                navigate(`/item-edit/${data.id}`)
                break;
            case "create_label":
                const listItemsLabel = [{ ...data, entity_type: "item" }]
                showCreateLabelModal(listItemsLabel, currentFolder)
                break;
            case "export":
                const listItemsExport = [{ ...data, entity_type: "item" }]
                showExportModal(listItemsExport, currentFolder);
                break;
            case "update_quantity":
                if (data.has_variants) {
                    showUpdateMultiQuantityModal([data], currentFolder)
                }
                else {
                    showUpdateSingleQuantityModal(data, currentFolder)
                }
                break;
            case "move":
                showMoveSingleQuantityModal(data, currentFolder)
                break;
            case "alert":
                showAlertModal([data], currentFolder)
                break;
            default:
                break;
        }
    }

    


    return (
        <div
            style={{
                width: "100%",
                paddingTop: "140%",
                borderRadius: 8,
                backgroundColor: token.colorBgContainer,
                borderColor: token.colorBorder,
                borderStyle: "solid",
                borderWidth: 1,
                position: "relative", overflow: "hidden"
            }}
        // onClick={e => navigate(`/item/${data.id}`)}
        >

            <div style={{ position: "absolute", top: 0, height: "100%", width: "100%", }}>
                <div style={{ position: "absolute", top: 0, width: "100%", zIndex: 2, display: "flex", paddingLeft: token.padding, }}>
                    <div style={{ flexGrow: 1, display: "flex" }} >

                        <Checkbox checked={check} onChange={e => selectItem({ ...data, entity_type: "item" }, e.target.checked)}></Checkbox>

                    </div>
                    <div>
                        <Dropdown
                            menu={{
                                items: options,
                                onClick: menuClick,

                            }}

                        >
                            <Button type="link" size="large"> <Icon.MoreOutlined /></Button>

                        </Dropdown>
                    </div>
                </div>

                <div style={{ height: "45%", width: "100%", overflow: "hidden" }}>

                    {thumbnail ?
                        <img src={thumbnail} style={{ width: "100%", height: "100%", objectFit: "cover", cursor: 'pointer' }} onClick={e => navigate(`/item-detail/${data.id}`, { state: { currentFolder, currentFolderPath }})}></img>
                        :
                        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Icon.FileImageOutlined style={{ fontSize: 48, color: token.colorPrimary }} onClick={e => navigate(`/item-detail/${data.id}`, { state: { currentFolder, currentFolderPath }})}/>

                        </div>

                    }
                </div>

                <div style={{ padding: token.paddingSM }}>


                    <Text.Title>
                        {data.name}
                    </Text.Title>

                    <div>
                        {data.variant_title}
                    </div>



                    <div >
                        <Space>
                            <div>
                                {Number.formatInt(data.folder_stock.quantity)}
                            </div>
                            <Divider type="vertical" ></Divider>
                            <div>
                                {Number.format(data.folder_stock.total_amount)}
                            </div>
                        </Space>

                        {data.has_variants &&
                            <div>Variants {data.folder_stock.variant_count}</div>
                        }

                    </div>
                    <div style={{marginTop:token.margin, justifyContent:"flex-end", display:"flex"}}>
                        <Tags tag_ids={data.tags}></Tags>
                    </div>

                </div>

            </div>

            {/* //{JSON.stringify(data)} */}
        </div>
    )
}




export default memo(Item);