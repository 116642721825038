import React, { useState } from 'react';
import { Button, theme, } from 'antd';
import * as Icon from '@ant-design/icons';
const { useToken } = theme;


const SaveButton = (props) => {
    return (
        <Button {...props} type='primary'><Icon.SaveFilled></Icon.SaveFilled> Save</Button>
    )
}

const CancelButton = (props) => {
    return (
        <Button {...props} type='default'>Cancel</Button>
    )
}



export {SaveButton,CancelButton}