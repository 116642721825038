import { useEffect, memo, useState } from "react";
import { Button, Flex, Space, Divider, Breadcrumb, Image, Dropdown, Table } from "antd";
import * as Icon from "@ant-design/icons";
import { BrowserRouter, useLocation, useNavigate, useParams } from "react-router-dom";
import ItemService from "services/item";
import ItemEditor from "components/item/Editor";
import { useLoading } from "context/loading";
import dayjs from "dayjs";
import { getQuantityItem } from "common/get_quantity_item";
import { getStockItem } from "common/get_stock_item";
import { getAlertItem } from "common/get_alert_item";
import Tags from "components/tag-list";
import CustomField from "services/custom_field";
import TagService from "services/tag";
import { useAppContext } from "context/app";
import * as Number from "common/number_format";
import FolderService from "services/folder";
import ListFolders from "./ListFolders";

const itemService = new ItemService();
const folderService = new FolderService();
const customFieldService = new CustomField();
const tagService = new TagService();

const ItemDetailPage = () => {
    const navigate = useNavigate();
    const [item, setItem] = useState({});
    const [folders, setFolders] = useState({});
    const [lstVariants, setLstVariants] = useState([]);
    const [stockItem, setStockItem] = useState({});
    const [photoShow, setPhotoShow] = useState({});
    const [previewVisible, setPreviewVisible] = useState(false);
    const [lstCustomFields, setLstCustomFields] = useState([]);
    const [lstAllCustomFields, setAllLstCustomFields] = useState([]);
    const [lstTags, setLstTags] = useState([]);
    const location = useLocation();
    const { currentFolder, currentFolderPath } = location.state;
    const { showExportModal, showCreateLabelModal, showUpdateSingleQuantityModal, showUpdateMultiQuantityModal, showMoveSingleQuantityModal, showAlertModal } = useAppContext();

    const { id } = useParams();

    const loading = useLoading();

    useEffect(() => {
        loadItem();
        loadAllCustomField();
    }, [id]);

    const loadAllCustomField = async () => {
        loading.showLoading();

        var result = await customFieldService.get();
        setAllLstCustomFields(result);
        loading.dismissLoading();
    };

    const loadItem = async () => {
        loading.showLoading();

        var data = await itemService.getByID(id);
        setItem(data);
        setPhotoShow(data.photos ? data.photos[0] : null);

        const lstFolder = await Promise.all(
            data.stock.map(async (stock) => {
                return await folderService.getByID(stock.folder_id);
            })
        );
        setFolders(lstFolder);

        if (data.has_variants) {
            const stockItem = data.stock.filter((stock) => stock.folder_id === currentFolder);
            const lstVariants = await Promise.all(
                stockItem[0].variant_stock.map(async (variantStock) => {
                    return await itemService.getByID(variantStock.item_id);
                })
            );
            setLstVariants(lstVariants);
        }

        var stock = getStockItem(data, currentFolder);
        setStockItem(stock);

        if (data.tags) {
            const lstTags = await Promise.all(
                data.tags.map(async (tagId) => {
                    return await tagService.getByID(tagId);
                })
            );
            setLstTags(lstTags);
        }

        if (data.custom_field) {
            const customField = await Promise.all(
                Object.keys(data.custom_field).map(async (key) => {
                    return await customFieldService.getByID(key);
                })
            );
            setLstCustomFields(customField);
        }

        loading.dismissLoading();
    };

    const handleChangePhoto = (direction) => {
        let index;
        if (direction === "back") {
            index = item.photos.findIndex((photo) => photo.id === photoShow.id) - 1;
            if (index === -1) {
                index = item.photos.length - 1;
            }
        } else if (direction === "next") {
            index = item.photos.findIndex((photo) => photo.id === photoShow.id) + 1;
            if (index === item.photos.length) {
                index = 0;
            }
        }

        setPhotoShow(item.photos[index]);
    };

    const iconStyle = { fontSize: 18 };
    const options = [
        {
            key: "alert",
            label: <div>Alert</div>,
            icon: <Icon.BellOutlined style={{ ...iconStyle }}></Icon.BellOutlined>,
        },

        {
            key: "move",
            label: <div>Move to Folder</div>,
            icon: <Icon.FolderOutlined style={{ ...iconStyle }}></Icon.FolderOutlined>,
        },

        {
            key: "update_quantity",
            label: <div>Update Quantity</div>,
            icon: <Icon.DiffOutlined style={{ ...iconStyle }}></Icon.DiffOutlined>,
        },

        {
            key: "create_label",
            label: <div>Create Label</div>,
            icon: <Icon.BarcodeOutlined style={{ ...iconStyle }}></Icon.BarcodeOutlined>,
        },

        {
            key: "export",
            label: <div>Export</div>,
            icon: <Icon.UploadOutlined style={{ ...iconStyle }}></Icon.UploadOutlined>,
        },

        {
            key: "transaction",
            label: <div>Transactions</div>,
            icon: <Icon.AccountBookOutlined style={{ ...iconStyle }}></Icon.AccountBookOutlined>,
        },

        {
            type: "divider", // Must have
        },

        {
            key: "clone",
            label: <div>Clone</div>,
            icon: <Icon.CopyOutlined style={{ ...iconStyle }}></Icon.CopyOutlined>,
        },
        {
            key: "edit",
            label: <div>Edit</div>,
            icon: <Icon.EditOutlined style={{ ...iconStyle }}></Icon.EditOutlined>,
        },
        {
            key: "edit",
            label: <div>Delete</div>,
            icon: <Icon.DeleteOutlined style={{ ...iconStyle }}></Icon.DeleteOutlined>,
        },
    ];

    const menuClick = ({ key }) => {
        switch (key) {
            case "edit":
                navigate(`/item-edit/${item.id}`);
                break;
            case "create_label":
                const listItemsLabel = [{ ...item, entity_type: "item" }];
                showCreateLabelModal(listItemsLabel, currentFolder);
                break;
            case "export":
                const listItemsExport = [{ ...item, entity_type: "item" }];
                showExportModal(listItemsExport, currentFolder);
                break;
            case "update_quantity":
                if (item.has_variants) {
                    showUpdateMultiQuantityModal([item], currentFolder);
                } else {
                    showUpdateSingleQuantityModal(item, currentFolder);
                }
                break;
            case "move":
                showMoveSingleQuantityModal(item, currentFolder);
                break;
            case "alert":
                showAlertModal([item], currentFolder);
                break;
            default:
                break;
        }
    };

    const renderAllCustomField = () => {
        const result = lstAllCustomFields.map((customField) => ({
            title: `${customField.name}`,
            dataIndex: `CustomField_${customField.id}`,
            key: `CustomField_${customField.id}`,
            width: "10%",
            render: (text, record) => {
                if (record.custom_field) {
                    for (const [key, value] of Object.entries(record.custom_field)) {
                        if (key === customField.id) {
                            return value;
                        }
                    }
                }

                return null;
            },
            fixed: "left",
        }));

        return result;
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "15%",
            render: (text, record) => {
                const img = record.photos && record.photos.length > 0 ? <Image width={30} src={record.photos[0].url} /> : <Icon.FileImageOutlined style={{ fontSize: "30px" }} />;

                const result = (
                    <Flex gap={10} align="center">
                        {img}
                        <Flex vertical>
                            <span>{record.name}</span>
                            <span>{record.variant_title}</span>
                        </Flex>
                    </Flex>
                );

                return result;
            },
            fixed: "left",
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            width: "5%",
            render: (text, record) => <div>{getQuantityItem(record, currentFolder)}</div>,
            fixed: "left",
        },
        {
            title: "Tag",
            dataIndex: "quantity",
            key: "quantity",
            width: "20%",
            render: (text, record) => <TagRenderer tags={record.tags} />,
            fixed: "left",
        },
        {
            title: "Notes",
            dataIndex: "notes",
            key: "notes",
            width: "20%",
            render: (text, record) => <span style={{ display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 2, overflow: "hidden", overflowWrap: "anywhere" }}>{text}</span>,
            fixed: "left",
        },
        {
            title: "Quantity Alert",
            dataIndex: "quantity_alert",
            key: "quantity_alert",
            width: "5%",
            render: (text, record) => <span>{getAlertItem(record, currentFolder) ? getAlertItem(record, currentFolder).quantity : ""}</span>,
            fixed: "left",
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            width: "5%",
            render: (text, record) => <div>{Number.format(record.price)}</div>,
            fixed: "left",
        },
        ...renderAllCustomField(),
    ];

    console.log({ item });

    return (
        <Flex vertical>
            {item && (
                <>
                    <Flex justify="space-between" style={{ margin: "10px 0" }}>
                        <Breadcrumb>
                            {currentFolderPath.map((x) => {
                                return (
                                    <Breadcrumb.Item>
                                        <a
                                            href=""
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate(`/folder/${x.id}`);
                                            }}
                                        >
                                            {x.name}
                                        </a>
                                    </Breadcrumb.Item>
                                );
                            })}
                        </Breadcrumb>

                        <div>
                            <span>ID: {item.id}</span>
                            {item.update_on && <span>, Updated at: {dayjs(item.update_on).format("DD/MM/YYYY h:mm A")}</span>}
                        </div>
                    </Flex>

                    <Flex justify="space-between" align="center" style={{ backgroundColor: "white", padding: "14px 20px", borderRadius: "8px" }}>
                        <Flex vertical>
                            <span style={{ fontSize: "22px", fontWeight: 500 }}>{item.name}</span>
                            <span>{item.variant_title}</span>
                        </Flex>
                        <div>
                            <Dropdown
                                menu={{
                                    items: options,
                                    onClick: menuClick,
                                }}
                            >
                                <Button type="link" size="large">
                                    <Icon.MoreOutlined />
                                </Button>
                            </Dropdown>
                            <Button
                                type="primary"
                                icon={<Icon.EditOutlined></Icon.EditOutlined>}
                                onClick={() => {
                                    navigate(`/item-edit/${item.id}`);
                                }}
                            >
                                Edit
                            </Button>
                        </div>
                    </Flex>

                    <Flex justify="space-between" style={{ backgroundColor: "white", padding: "14px 20px", borderRadius: "8px", marginTop: "20px" }}>
                        <Flex vertical gap={4}>
                            <span>Quantity</span>
                            <span>{Number.formatInt(stockItem.quantity)}</span>
                        </Flex>

                        <Flex vertical gap={4}>
                            <span>Min Level</span>
                            <span>{item.quantity_alerts && getAlertItem(item, currentFolder) ? getAlertItem(item, currentFolder).quantity : ""}</span>
                        </Flex>

                        <Flex vertical gap={4}>
                            <span>Price</span>
                            <span>{Number.format(item.price)}</span>
                        </Flex>

                        <Flex vertical gap={4}>
                            <span>Total value</span>
                            <span>{Number.format(stockItem.total_amount)}</span>
                        </Flex>
                    </Flex>

                    <Flex gap={20} vertical style={{ backgroundColor: "white", padding: "14px 20px", borderRadius: "8px", marginTop: "20px" }}>
                        <Flex align="center" vertical gap={30}>
                            <span style={{ fontSize: "22px", fontWeight: 500 }}>Product Information</span>

                            {item.photos && photoShow ? (
                                <>
                                    <img
                                        height={200}
                                        src={photoShow.url}
                                        style={{
                                            borderRadius: "8px",
                                            objectFit: "contain",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setPreviewVisible(true)}
                                    />

                                    <Flex gap={14} justifyContent="center">
                                        <Button icon={<Icon.LeftOutlined />} onClick={() => handleChangePhoto("back")} disabled={item.photos?.findIndex((photo) => photo.id === photoShow.id) === 0} />
                                        {item.photos?.map((photo) => (
                                            <img
                                                key={photo.id}
                                                style={{
                                                    borderRadius: "8px",
                                                    objectFit: "cover",
                                                    cursor: "pointer",
                                                    opacity: photo.id === photoShow.id ? 1 : 0.5,
                                                }}
                                                width={60}
                                                height={30}
                                                src={photo.url}
                                                onClick={() => setPhotoShow(photo)}
                                            />
                                        ))}
                                        <Button
                                            icon={<Icon.RightOutlined />}
                                            onClick={() => handleChangePhoto("next")}
                                            disabled={item.photos?.findIndex((photo) => photo.id === photoShow.id) === item.photos.length - 1}
                                        />
                                    </Flex>

                                    {previewVisible && (
                                        <Image.PreviewGroup
                                            preview={{
                                                current: item.photos.findIndex((photo) => photo.id === photoShow.id),
                                                onChange: (current) => setPhotoShow(item.photos[current]),
                                                visible: previewVisible,
                                                onVisibleChange: (visible) => setPreviewVisible(visible),
                                            }}
                                        >
                                            {item.photos?.map((photo) => (
                                                <Image key={photo.id} src={photo.url} style={{ display: "none" }} />
                                            ))}
                                        </Image.PreviewGroup>
                                    )}
                                </>
                            ) : (
                                <Icon.FileImageOutlined style={{ fontSize: "200px" }} />
                            )}
                        </Flex>

                        <Flex gap={6} vertical style={{ padding: "12px 0", borderBottom: "1px solid #ccc", flexWrap: "wrap" }}>
                            <span style={{ fontWeight: 500 }}>Tags </span>
                            <Flex>
                                {lstTags.map((tag) => (
                                    <span
                                        key={tag.id}
                                        style={{
                                            backgroundColor: tag.color,
                                            padding: "4px 12px",
                                            borderRadius: "8px",
                                            margin: "8px 8px 0 0",
                                        }}
                                    >
                                        {tag.title}
                                    </span>
                                ))}
                            </Flex>
                        </Flex>

                        <Flex gap={6} vertical style={{ padding: "12px 0", borderBottom: "1px solid #ccc" }}>
                            <span style={{ fontWeight: 500 }}>Notes</span>
                            <span style={{ overflowWrap: "break-word" }}>{item.notes}</span>
                        </Flex>

                        <Flex gap={6} vertical style={{ padding: "12px 0" }}>
                            {lstCustomFields.map((customField) => (
                                <div
                                    key={customField.id}
                                    style={{
                                        borderRadius: "8px",
                                        marginRight: "8px",
                                    }}
                                >
                                    <span style={{ fontWeight: 500 }}>{`${customField.name}: `}</span>
                                    <span>{customField.default_value}</span>
                                </div>
                            ))}
                        </Flex>
                    </Flex>

                    <div style={{ backgroundColor: "white", padding: "14px 20px", borderRadius: "8px", marginTop: "20px" }}>
                        {folders.length > 0 && <ListFolders item={item} lstFolder={folders} currentFolder={currentFolder} />}
                    </div>

                    <Flex gap={20} vertical style={{ backgroundColor: "white", padding: "14px 20px", borderRadius: "8px", marginTop: "20px" }}>
                        <span style={{ fontSize: "22px", fontWeight: 500 }}>QR & barcode</span>
                        <span>You can use QR codes or barcodes to track the inventory of your products or assets.</span>
                    </Flex>

                    <div style={{ backgroundColor: "white", padding: "14px 20px", borderRadius: "8px", marginTop: "20px" }}>
                        {item.has_variants && <Table dataSource={lstVariants} columns={columns}></Table>}
                    </div>
                </>
            )}
        </Flex>
    );
};

const TagRenderer = ({ tags }) => {
    const [lstTags, setLstTags] = useState([]);
    const loading = useLoading();

    useEffect(() => {
        const fetchTags = async () => {
            loading.showLoading();
            const lstTags = await Promise.all(
                tags.map(async (tagId) => {
                    return await tagService.getByID(tagId);
                })
            );

            setLstTags(lstTags);
            loading.dismissLoading();
        };

        if (tags.length > 0) {
            fetchTags();
        }
    }, [tags]);

    return (
        <Flex gap={2} style={{ flexWrap: "wrap" }}>
            {lstTags.map((tag) => (
                <span
                    key={tag.id}
                    style={{
                        backgroundColor: tag.color,
                        padding: "4px 8px",
                        borderRadius: "8px",
                    }}
                >
                    {tag.title}
                </span>
            ))}
        </Flex>
    );
};

export default memo(ItemDetailPage);
