import {
  FontSizeOutlined,
  CheckSquareOutlined,
  FieldBinaryOutlined,
  DownSquareOutlined,
  PhoneOutlined,
  MailOutlined,
  LinkOutlined,
  CalendarOutlined,
  BarcodeOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import * as field_type_const from "components/CustomField/constants";

function ListCustomFields({ listField, selectedField, setSelectedField, typeField, setTypeField }) {
  const showIcon = (dataType) => {
    switch (dataType) {
      case field_type_const.SHORT_TEXT:
        return <FontSizeOutlined />;
      case field_type_const.LONG_TEXT:
        return <FontSizeOutlined />;
      case field_type_const.CHECK_BOX:
        return <CheckSquareOutlined />;
      case field_type_const.ROUND_NUMBER:
        return <FieldBinaryOutlined />;
      case field_type_const.DECIMAL_NUMBER:
        return <FieldBinaryOutlined />;
      case field_type_const.DROPDOWN:
        return <DownSquareOutlined />;
      case field_type_const.PHONE_NUMBER:
        return <PhoneOutlined />;
      case field_type_const.EMAIL:
        return <MailOutlined />;
      case field_type_const.LINK:
        return <LinkOutlined />;
      case field_type_const.DATE:
        return <CalendarOutlined />;
      case field_type_const.SCANNER:
        return <BarcodeOutlined />;
      case field_type_const.FILE:
        return <FileOutlined />;
      default:
        break;
    }
  };

  return (
    <>
      {typeField === "SUGGESTED" ? <p>SUGGESTED FIELDS</p> : <p>CHOOSE FIELD TYPE</p>}

      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        {listField.map((item, index) => (
          <div
            key={index}
            style={{ flex: "0 1 calc(50% - 10px)" }}
            onClick={() => {
              setSelectedField(item);
            }}
          >
            <p style={{ boxShadow: "rgba(60, 64, 88, 0.2) 0px 5px 10px", padding: "12px 16px", borderRadius: "8px", cursor: "pointer", border: item === selectedField ? "1px solid #1677ff" : "" }}>
              <span style={{ marginRight: "8px", color: item === selectedField ? "#1677ff" : "" }}>{showIcon(item.data_type)}</span>
              {item.name}
            </p>
          </div>
        ))}
      </div>

      {typeField === "SUGGESTED" ? (
        <Button type="link" onClick={() => setTypeField("OWN")}>
          Create your own field
        </Button>
      ) : (
        <Button type="link" onClick={() => setTypeField("SUGGESTED")}>
          Back to suggested fields
        </Button>
      )}
    </>
  );
}

export default ListCustomFields;
