import { ColorPicker, Form, Input, Modal, Space } from "antd";
import { generate, green, presetPalettes, red } from "@ant-design/colors";
import React, { useState, useMemo, useEffect, useContext } from "react";
import TagService from "services/tag";
import { useNotification } from "context/notification";
import { useLoading } from "context/loading";

const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map(([label, colors]) => ({
    label,
    colors,
  }));

function AddOrEditTagModal({ visible, onClose, onAdd = null, value = null, onEdit = null }) {
  const [form] = Form.useForm();
  const [color, setColor] = useState("");
  const [hexString, setHexString] = useState("");
  const { success, error } = useNotification();
  const { showLoading, dismissLoading } = useLoading();

  useEffect(() => {
    if (!visible) return;

    if (value) {
      form.setFieldsValue(value);
      setColor(value.color);
      setHexString(value.color);
    } else {
      form.resetFields();
      setColor("");
      setHexString("");
    }
  }, [visible]);

  const onOk = () => {
    form.submit();
  };

  const onFinish = async () => {
    showLoading("Saving");
    const data = { ...form.getFieldsValue() };
    const tagService = new TagService();

    if (value) {
      const result = await tagService.edit(data);
      if (result) {
        onEdit(data);
        success(`${data.name} has been successfully updated`);
      } else {
        error("Failed to update the tag. Please try again later.");
      }
    } else {
      const result = await tagService.add(data);
      if (result) {
        onAdd(result);
        success(`${data.name} has been successfully created`);
      } else {
        error("Failed to add the tag. Please try again later.");
      }
    }

    dismissLoading();
  };

  const presets = genPresets({
    color: ["264653", "2a9d8f", "e9c46a", "f4a261", "e76f51"],
  });

  const handleColorChange = (value) => {
    const hex = typeof value === "string" ? value : value?.toHexString();
    setColor(hex);
    setHexString(hex);
    form.setFieldsValue({ color: hex });
  };

  return (
    <Modal
      title={<p style={{ fontSize: "18px", textAlign: "center" }}>{value ? "Update Tag" : "Add Tag"}</p>}
      open={visible}
      onOk={onOk}
      onCancel={onClose}
      maskClosable={false}
      okText={value ? "Update" : "Add"}
    >
      <Form layout="vertical" form={form} initialValues={{}} onFinish={onFinish}>
        <Form.Item name="id" hidden>
          <Input></Input>
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Title"></Input>
        </Form.Item>

        <Form.Item
          label="Color"
          name="color"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Space>
            <ColorPicker value={color} onChange={handleColorChange} presets={presets} />
            <span>{hexString}</span>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddOrEditTagModal;
