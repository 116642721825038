import React, { useContext, useEffect, useState, useRef } from "react";
import { PlusOutlined, LeftCircleOutlined, RightCircleOutlined, DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Image, Popconfirm, Upload } from "antd";
import FileService from "services/file";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy, horizontalListSortingStrategy } from "@dnd-kit/sortable";
import { useNotification } from "context/notification";
import { CSS } from "@dnd-kit/utilities";
import "./MultiDocumentsPicker.css";

const MultiDocumentsPicker = ({
  value,
  onChange,
  maxFiles = 2,
  acceptedFormats = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/plain",
  ],
  maxFileSize = 10485760,
}) => {
  const [fileList, setFileList] = useState([]);
  const { success, error } = useNotification();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (value) {
      setFileList(value);
    }
  }, [value]);

  const DraggableUploadListItem = ({ originNode, file }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: file.uid,
    });

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
      cursor: "move",
      position: "relative",
    };

    return (
      <div ref={setNodeRef} style={style} className={isDragging ? "item-file is-dragging" : "item-file"} {...attributes} {...listeners}>
        {file.status === "error" && isDragging ? originNode.props.children : originNode}
        <Popconfirm title="Are you sure you want to delete this file?" onConfirm={() => handleRemove(file)} okText="Yes" cancelText="No">
          <DeleteOutlined className="btn-remove-file" />
        </Popconfirm>
      </div>
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    console.log({ newFileList });
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      let fileListNew;

      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);

        fileListNew = arrayMove(prev, activeIndex, overIndex);
        return fileListNew;
      });

      onChange(fileListNew);
    }
  };

  var check = false;
  const handleBeforeUpload = async (file, newFileList) => {
    try {
      if (newFileList.length > maxFiles - fileList.length) {
        if (!check) {
          error(`You can only upload up to ${maxFiles} files`);
          check = true;
        }
        return Promise.reject();
      }

      if (Number(file.size) > maxFileSize) {
        error(`File size must be smaller than ${maxFileSize}`);
        return Promise.reject();
      }

      setLoading(true);
      const result = await uploadFile(file);
      const newFile = {
        id: result.id,
        name: file.name,
        url: result.url,
      };

      setFileList((prevFileList) => {
        const updatedFileList = [...prevFileList, newFile];
        onChange(updatedFileList);
        return updatedFileList;
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

    return Promise.reject();
  };

  const uploadFile = async (file) => {
    const fileService = new FileService();
    try {
      const formData = new FormData();
      formData.append("file", file);

      const result = await fileService.upload(formData);
      return result;
    } catch (e) {
      error("File was not uploaded. Please try again later");
    }
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((f) => f.id !== file.id);
    setFileList(newFileList);
    onChange(newFileList);
  };

  const uploadButton = <Button icon={loading ? <LoadingOutlined /> : <PlusOutlined />}>Click to Upload</Button>;

  return (
    <>
      <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
        <SortableContext items={fileList?.map((i) => i.uid)} strategy={horizontalListSortingStrategy}>
          <Upload
            listType="text"
            fileList={fileList}
            onChange={handleChange}
            multiple
            maxCount={maxFiles}
            accept={acceptedFormats.join(",")}
            beforeUpload={handleBeforeUpload}
            itemRender={(originNode, file) => <DraggableUploadListItem originNode={originNode} file={file} />}
            showUploadList={{
              showRemoveIcon: false,
            }}
            disabled={loading}
          >
            {fileList?.length >= maxFiles ? null : uploadButton}
          </Upload>
        </SortableContext>
      </DndContext>
    </>
  );
};

export default MultiDocumentsPicker;
